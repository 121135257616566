// 标尺线头部和尾部的宽度, 类似于大写"I"的顶部和底部的宽度
const dotWidth = 14

/**
 *
 * @param { Boolean, String } isDefault 是否为新增余料时的展示板绘制, 展示板会传递rect或者L字符串, 而余料的真正展示会传递false
 * @param { Object } drawSize 余料尺寸, 4个参数, width: 余料宽度, long: 余料长度, min_width: 余料短边宽度, min_long: 余料短边长度
 * @param { Number } scale 缩放比例
 */
export function dealSupplusPoints(isDefault, drawSize, scale) {
  var drawObj = drawSize
  // 绘制矩形余料
  var width = drawObj.width / scale
  var long = drawObj.long / scale
  var min_width = drawObj.min_width / scale
  var min_long = drawObj.min_long / scale
  let shape = []
  if (min_long == 0) {
    // 绘制矩形点位
    shape = [
      { x: 0, y: 0 },
      { x: width, y: 0 },
      { x: width, y: long },
      { x: 0, y: long },
    ]
  } else {
    // 绘制L形点位
    shape = [
      { x: 0, y: 0 },
      { x: min_width, y: 0 },
      { x: min_width, y: long - min_long },
      { x: width, y: long - min_long },
      { x: width, y: long },
      { x: 0, y: long },
    ]
  }

  // 绘制板件宽度点位, 类似于横向的大写"I", 有4个顶点和中间的2个交点
  var str = isDefault ? `${isDefault}宽度\n(W)` : drawObj.width + ''
  var plankW = acrossPoints(width, str)

  str = isDefault ? `${isDefault}长度\n(H)` : drawObj.long + ''
  var plankH = verticalPoints(long, str)

  if (min_long != 0) {
    str = isDefault ? '短边宽度' : drawObj.min_width + ''
    var plankMinW = acrossPoints(min_width, str)

    str = isDefault ? '短边长度' : drawObj.min_long + ''
    var plankMinH = verticalPoints(min_long, str)

    return {
      shape: shape,
      plankW: plankW,
      plankH: plankH,
      plankMinW: plankMinW,
      plankMinH: plankMinH,
    }
  }

  return {
    shape: shape,
    plankW: plankW,
    plankH: plankH,
  }
}

// 获取文字宽度, 用于中心绘制
function calcTextSize(text) {
  var span = document.createElement('span')
  var result = {}
  result.width = span.offsetWidth
  result.height = span.offsetHeight
  span.style.visibility = 'hidden'
  span.style.fontSize = '14px'
  span.style.fontFamily = 'PingFangSC-Regular, PingFang SC'
  span.style.display = 'inline-block'
  document.body.appendChild(span)
  if (typeof span.textContent != 'undefined') {
    span.textContent = text
  } else {
    span.innerText = text
  }
  result.width = parseFloat(window.getComputedStyle(span).width) - result.width
  result.height =
    parseFloat(window.getComputedStyle(span).height) - result.height
  document.body.removeChild(span)
  return result
}

// 获取横向标尺线点位
function acrossPoints(width, str) {
  // 绘制板件宽度点位, 类似于横向的大写"I", 有4个顶点和中间的2个交点
  var rectWidth = [
    { x: 0, y: 0 }, // 移动至第一个点
    { x: 0, y: dotWidth }, // 绘制到第二个点
    { x: 0, y: dotWidth / 2 }, // 移动到第一个交点
    { x: width, y: dotWidth / 2 }, // 绘制到第二个交点
    { x: width, y: 0 }, // 移动到第三个点
    { x: width, y: dotWidth }, // 绘制到第四个点
  ]
  // 获取文字宽度和高度
  var textSize = calcTextSize(str)
  // 计算文字绘制的起始横坐标, 取余料宽度和文字宽度的差
  var startLeft = (width - textSize.width) / 2
  var rectText = { x: startLeft, y: 0, str: str }
  return {
    line: rectWidth,
    text: rectText,
  }
}

// 获取竖向标尺线点位
function verticalPoints(long, str) {
  // 绘制板件长度点位, 同样类似于大写"I", 4个定点2个交点
  var rectLong = [
    { x: 0, y: 0 }, // 移动至第一个点
    { x: dotWidth, y: 0 }, // 绘制到第二个点
    { x: dotWidth / 2, y: 0 }, // 移动到第一个交点
    { x: dotWidth / 2, y: long }, // 绘制到第二个交点
    { x: 0, y: long }, // 移动到第三个点
    { x: dotWidth, y: long }, // 绘制到第四个点
  ]
  var textSize = calcTextSize(str)
  // 获取文字绘制的起始纵坐标
  var startTop = (long - textSize.width) / 2 + 16
  var rectText = { x: 0, y: startTop, str: str }
  return {
    line: rectLong,
    text: rectText,
  }
}
