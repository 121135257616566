import { translate } from '@/util/commonFun'
import { toDecimal } from '@/util/commonFuncs'
import { languageMap } from '@/views/materialsList/config/languageMap'

import { PartType, PlankType } from '../types/part'

// 样例表头
const sampleHead = ['项目地址', '客户资料', '工厂单号', '房间名称', '订单备注']

// 导入数据必须存在的列
export const indispensableHeaderDataList = [
  translate(languageMap.get('材质')),
  translate(languageMap.get('颜色')),
  translate(languageMap.get('厚度')),
  translate(languageMap.get('成品长度')),
  translate(languageMap.get('成品宽度')),
  translate(languageMap.get('数量')),
  translate(languageMap.get('纹理')),
] as const

export type NecessaryTableHeaderType =
  typeof indispensableHeaderDataList[number]

// 处理excel表格数据
export const dealExcelData = (data: Record<string, any>[]) => {
  const plankArr: Partial<PlankType>[] = []
  for (let i = 0; i < data.length; ++i) {
    const plank = data[i]
    // 封边keys 封边 -> 左右为标准 -> 前：下 后：上 -> 结果取左下右上的顺序
    const edgeSort = new Map([
      ['左封边', 1],
      ['后封边', 2],
      ['下封边', 2],
      ['右封边', 3],
      ['前封边', 4],
      ['上封边', 4],
    ])
    const edgeKeys = Object.keys(plank)
      .filter((e) => e.includes('封边'))
      .sort((a, b) => edgeSort.get(a)! - edgeSort.get(b)!)
    if (plank[translate(languageMap.get('纹理'))] === '横纹') {
      ;[
        plank[translate(languageMap.get('长度'))],
        plank[translate(languageMap.get('宽度'))],
      ] = [
        plank[translate(languageMap.get('宽度'))],
        plank[translate(languageMap.get('长度'))],
      ]
    }
    const newPlank: Partial<PartType> = {}
    const material =
      plank[translate(languageMap.get('材质'))] ??
      plank[translate(languageMap.get('材料'))]
    const thick = material ? material.match(/^\d+(\.\d+)?/) ?? [] : []

    newPlank.thick = thick[0] ?? 0
    newPlank.matCode = material ? material.replace(newPlank.thick, '') : ''
    let edgeArr: string[] = []
    if (plank[translate(languageMap.get('封边信息'))]) {
      if (plank[translate(languageMap.get('封边信息'))].includes(':')) {
        edgeArr = plank[translate(languageMap.get('封边信息'))]
          .split(':')[1]
          .split(/前|后|左|右|上|下/)
          .filter((v: any) => v && v !== ' ')
      } else if (plank[translate(languageMap.get('封边信息'))].includes(': ')) {
        edgeArr = plank[translate(languageMap.get('封边信息'))]
          .split(': ')[1]
          .split(/前|后|左|右|上|下/)
          .filter((v: any) => v && v !== ' ')
      }
    } else if (edgeKeys.length) {
      edgeArr = edgeKeys.map((e) => plank[e])
    }
    if (edgeArr.length < 4) {
      const n = 4 - edgeArr.length
      edgeArr.push(...new Array(n).fill('0'))
    }
    edgeArr = edgeArr.map((item: any) => {
      if (!item) {
        item = '0'
      }
      return item
    })
    newPlank.edgeInfo =
      edgeArr.length > 0
        ? `←${edgeArr[0]}↓${edgeArr[1]}→${edgeArr[2]}↑${edgeArr[3]}`
        : ''

    newPlank.plankID = plank[translate(languageMap.get('板号'))]
    newPlank.partName = plank[translate(languageMap.get('名称'))]
    newPlank.loc = plank[translate(languageMap.get('柜体'))]
    newPlank.customer_name =
      plank[translate(languageMap.get('客户'))] ?? plank['customer']
    newPlank.frontEdge = plank[translate(languageMap.get('前封边'))]
    newPlank.backEdge = plank[translate(languageMap.get('后封边'))]
    newPlank.leftEdge = plank[translate(languageMap.get('左封边'))]
    newPlank.rightEdge = plank[translate(languageMap.get('右封边'))]
    newPlank.plank_remarks = plank[translate(languageMap.get('板件备注'))]
    newPlank.remark = plank['房间备注']
    newPlank.roomName =
      plank[translate(languageMap.get('房间'))] ?? plank['orderRoomName']
    newPlank.remarks = plank['remarks']
    newPlank.partName =
      plank[translate(languageMap.get('板件名称'))] ?? plank['工件名称']
    newPlank.orderNo =
      plank[translate(languageMap.get('订单号'))] ?? plank['orderNo']
    newPlank.plankNum = plank[translate(languageMap.get('板件条码'))]
    newPlank.type =
      plank[translate(languageMap.get('门板'))] ===
        translate(languageMap.get('非门板')) ||
      !translate(languageMap.get('门板'))
        ? 'Plank'
        : 'SingleDoor'
    newPlank.address =
      plank[translate(languageMap.get('项目地址'))] ?? plank['orderAddress']
    newPlank.specialShape = plank[translate(languageMap.get('异形'))]
    newPlank.specHeight =
      plank[translate(languageMap.get('成品长度'))] ??
      plank[translate(languageMap.get('长度'))] ??
      plank['长']
    newPlank.specWidth =
      plank[translate(languageMap.get('成品宽度'))] ??
      plank[translate(languageMap.get('宽度'))] ??
      plank['宽']
    newPlank.thick =
      plank[translate(languageMap.get('厚度'))] ??
      plank['厚'] ??
      plank['开料厚'] ??
      thick[0] ??
      0
    newPlank.oRect = {
      x: 0,
      y: 0,
      width: newPlank.specWidth ? Number(newPlank.specWidth) : 0,
      height: newPlank.specHeight ? Number(newPlank.specHeight) : 0,
    }
    newPlank.fullSize = {}
    newPlank.rect = {
      x: 0,
      y: 0,
      width: toDecimal(
        Number(newPlank.oRect.width) - Number(edgeArr[0]) - Number(edgeArr[2]),
        2
      ),
      height: toDecimal(
        Number(newPlank.oRect.height) - Number(edgeArr[1]) - Number(edgeArr[3]),
        2
      ),
    }
    newPlank.realRect = JSON.parse(JSON.stringify(newPlank.rect))
    newPlank.amount = plank[translate(languageMap.get('数量'))]

    if (plank[translate(languageMap.get('高光板'))] === undefined) {
      // 就根据材质来做初始化
      newPlank.is_high_gloss_plank = newPlank.matCode?.includes('高光_')
    } else {
      newPlank.is_high_gloss_plank =
        plank[translate(languageMap.get('高光板'))] ===
        translate(languageMap.get('高光板'))
          ? true
          : false
    }
    switch (String(plank[translate(languageMap.get('纹理'))]).trim()) {
      case translate(languageMap.get('竖纹')):
        newPlank.texDir = 'normal'
        newPlank.srcTexDir = 'normal'
        break
      case translate(languageMap.get('横纹')):
        newPlank.texDir = 'reverse'
        newPlank.srcTexDir = 'reverse'
        break
      case translate(languageMap.get('无纹理')):
        newPlank.texDir = 'notcare'
        newPlank.srcTexDir = 'notcare'
        break
      default:
        newPlank.texDir = 'notcare'
        newPlank.srcTexDir = 'notcare'
        break
    }

    newPlank.hsInfo = plank[translate(languageMap.get('孔槽数量'))]
    newPlank.texture = plank[translate(languageMap.get('颜色'))]
    newPlank.holes = []
    newPlank.slots = []
    newPlank.sholes = []
    newPlank.sslots = []
    newPlank.realCurve = [
      { x: 0, y: 0 },
      { x: newPlank.oRect.width, y: 0 },
      { x: newPlank.oRect.width, y: newPlank.oRect.height },
      { x: 0, y: newPlank.oRect.height },
    ]
    newPlank.name = '导入的板件'
    newPlank.matRotatable = newPlank.texDir == 'notcare' ? true : false
    plankArr.push(newPlank)
  }
  return { plankList: plankArr }
}

// csv文件数据中可能会包含订单的信息，因此需要先处理订单信息（即是样表信息）
export const dealCsvOrderInfo = (data: any[]) => {
  let isSpecialHead = data[0] && data[0].split(',').filter((v: any) => v)
  let newData: any[] = data
  let showSample = true
  isSpecialHead.color = '#E7E7E7'
  isSpecialHead = isSpecialHead.length
    ? isSpecialHead.every((v: any) => sampleHead.includes(v))
    : false
  let orderNo = ''
  let orderAddress = ''
  let orderRoomName = ''
  let customer = ''
  let remarks = ''
  const originalData = {
    address: '',
    clientData: '',
    factoryOrderNum: '',
    orderRemark: '',
    roomName: '',
  }
  let sData: any = {}
  if (isSpecialHead) {
    const sample = data[1].split(',').map((it: any) => it.replace(/&/g, ','))
    const template = JSON.parse(JSON.stringify({ ...originalData }))
    Object.keys(template).forEach((key, idx) => {
      template[key] = sample[idx] ?? ''
    })
    sData = template
    newData = data.slice(2)
    orderNo = sample[2]
    orderAddress = sample[0]
    orderRoomName = sample[3]
    customer = sample[1]
    remarks = sample[4]
  } else {
    // 导入表格没有表头两行时，不显示样表头
    showSample = false
  }
  // 筛选分隔之后的空白数据
  const allData = newData
    .filter((v) => v)
    .map((data) => {
      // 去掉数据中的引号和制表符
      return data.replace(/"/g, '').replace(/\t/g, '')
    })
  const arr = []
  // 去除回车符号(直接用console打印单条数据没有这个回车符号, 但是打印整体就有, 没有找到原因, 但是为了保险, 就还是去掉了一下这个回车符号)
  // 获取csv表格的表头
  const keys = allData[0].split(',')
  keys.forEach((item: string, index: number) => {
    if (item === '名称') {
      keys[index] = '板件名称'
    } else if (item === '长度') {
      keys[index] = '成品长度'
      keys.push('厚度')
    } else if (item === '宽度') {
      keys[index] = '成品宽度'
    } else if (item === '封边信息') {
      keys.push('前封边')
      keys.push('后封边')
      keys.push('左封边')
      keys.push('右封边')
    }
  })
  // 删除表头
  allData.splice(0, 1)
  // 处理csv表格内容, 将表头和内容一一对应
  for (let i = 0; i < allData.length; i++) {
    const plankInfo = allData[i].split(',')
    const obj: any = { orderNo, orderAddress, orderRoomName, customer, remarks }
    for (let j = 0; j < plankInfo.length; j++) {
      obj[keys[j]] = plankInfo[j]
    }
    arr.push(obj)
  }
  return {
    data: arr,
    headerItemAddress: sData,
    showSample,
  }
}

// 检测导入的是csv数据的样表头是否完整
export const checkCsvData = (data: any[]) => {
  return new Promise<Record<string, any>[]>((resolve, reject) => {
    let keys: string[] = []
    if (typeof data[0] === 'object') {
      keys = Object.keys(data[0])
    }
    // 导入的数据中存在的表头项
    const existTableHeaderList = keys.filter((item: NecessaryTableHeaderType) =>
      indispensableHeaderDataList.includes(item)
    )

    // 缺失的列
    const lackTableHeaderList = getArrDifference(
      existTableHeaderList,
      indispensableHeaderDataList as unknown as string[]
    )
    // 缺失的列字符串
    let lackTableString = ''
    let showTableDialog = false
    let isShowLackNeedTableHeader = false
    let isShowLackNeedTableHeaderData = true
    if (lackTableHeaderList.length) {
      lackTableHeaderList.forEach((item: string, index: number) => {
        if (index !== lackTableHeaderList.length - 1) {
          lackTableString += `"${item}、"`
        } else {
          lackTableString += `"${item}"`
        }
      })
      showTableDialog = true
      isShowLackNeedTableHeader = true
      isShowLackNeedTableHeaderData = false
      reject({
        showTableDialog,
        isShowLackNeedTableHeader,
        isShowLackNeedTableHeaderData,
        lackTableString,
      })
    }
    resolve(data)
  })
}

const isNumericPositive = (value: any) => {
  const number = Number(value)
  return !isNaN(number) && number > 0
}

const getArrDifference = (arr1: string[], arr2: string[]) => {
  return arr1.concat(arr2).filter(function (v, i, arr) {
    return arr.indexOf(v) === arr.lastIndexOf(v)
  })
}
