var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-modal',{attrs:{"title":_setup.props.title,"centered":""},on:{"cancel":_setup.handleCancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a-button',{key:"back",attrs:{"id":"send_modal_cancel_btn"},on:{"click":_setup.handleCancel}},[_vm._v("取消")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_setup.props.isLoading,"id":"send_modal_submit_btn"},on:{"click":_setup.handleOk}},[_vm._v("确定")])]},proxy:true}]),model:{value:(_setup.display),callback:function ($$v) {_setup.display=$$v},expression:"display"}},[_c('div',{staticClass:"send-factory-setting"},[_c('div',{staticClass:"setting-item"},[_c('span',{staticClass:"lable mr10"},[_vm._v("标签模板选择:")]),_c('a-select',{staticClass:"temp-select",attrs:{"dropdownMatchSelectWidth":"","dropdownClassName":"tex-dir-select"},on:{"change":_setup.handleSelectTemp},model:{value:(_setup.currentTagTempID),callback:function ($$v) {_setup.currentTagTempID=$$v},expression:"currentTagTempID"}},_vm._l((_setup.template_list),function(template,index){return _c('a-select-option',{key:index,attrs:{"value":template.id,"id":`send_modal_tagTemp_option_${index}`}},[_vm._v(" "+_vm._s(template.name)+" ")])}),1)],1),_c('div',{staticClass:"setting-item"},[_c('span',{staticClass:"lable mr10"},[_vm._v("标签文件类型:")]),_c('a-radio-group',{model:{value:(_setup.tagDocType),callback:function ($$v) {_setup.tagDocType=$$v},expression:"tagDocType"}},_vm._l((_setup.tagDocTypes),function(radio,index){return _c('a-radio',{key:radio,attrs:{"value":radio,"id":`send_modal_tagDocType_radio_${index}`,"disabled":index == 1 &&
            _setup.store.state.ncSetting.labelImageFormatNew == 'bmp' &&
            _setup.store.state.ncSetting.bmp_color_style == 1 &&
            _setup.props.print_color_tag}},[_c('span',{attrs:{"id":`send_modal_tagDocType_radio_${index}_label`}},[_vm._v(_vm._s(radio))]),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("当前为bmp后缀的图片，编码格式黑白，无法输出彩色标签图片")])]),(
                index == 1 &&
                _setup.store.state.ncSetting.labelImageFormatNew == 'bmp' &&
                _setup.store.state.ncSetting.bmp_color_style == 1 &&
                _setup.props.print_color_tag
              )?_c('img',{staticClass:"ml2",staticStyle:{"height":"16px","width":"16px"},attrs:{"src":require("@/assets/labelColor/error-circle.png"),"alt":""}}):_vm._e()],2)],1)}),1)],1),_c('div',{staticClass:"setting-item"},[_c('span',{staticClass:"lable mr10"},[_vm._v("生产线:")]),_vm._v(" "+_vm._s(_setup.props.proLineName ? _setup.props.proLineName : _setup.store.state.ncSetting.process_setting_name)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }