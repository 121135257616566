var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.isShowBox),expression:"isShowBox"}],staticClass:"container"},[_c('div',{staticClass:"common-height flex flex-main--justify"},[_c('div',{staticClass:"tip"},[_c('a-checkbox',{on:{"change":_setup.handleSelectAllChange},model:{value:(_setup.isSelectAll),callback:function ($$v) {_setup.isSelectAll=$$v},expression:"isSelectAll"}}),_c('div',{staticClass:"ml20 supplement-title cursor-pointer",on:{"click":function($event){_setup.isExpand = !_setup.isExpand}}},[_c('a-icon',{attrs:{"type":_setup.isExpand ? 'up' : 'down'}}),_c('span',{staticClass:"bold",domProps:{"innerHTML":_vm._s(
            _vm.$t('materialPage.chooseAwaitStoreQuantity', {
              total: _setup.tip(_setup.state.tableBaseData.length),
              quantity: _setup.tip(_setup.state.tableFinalData.length),
              chooseQuantity: _setup.tip(_setup.selectPart.length),
            })
          )}})],1),(_setup.state.isLoading)?_c('span',{staticClass:"high ml20"},[_vm._v("待排版库数据加载中 "),_c('a-icon',{attrs:{"type":"loading"}})],1):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.isExpand),expression:"isExpand"}],staticClass:"content"},[(_setup.state.tableFinalData.length)?_c(_setup.virtualList,{attrs:{"table-data":_setup.state.tableFinalData,"table-head-prop":_setup.bujianManageTableConfig,"show-data-length":7,"single-item-height":40,"is-show-back-top":false,"preffixID":"await_store"},scopedSlots:_vm._u([{key:"select",fn:function(scope){return [_c('a-checkbox',{on:{"change":_setup.handleSingleSelectChange},model:{value:(scope.part.isSelected),callback:function ($$v) {_vm.$set(scope.part, "isSelected", $$v)},expression:"scope.part.isSelected"}})]}}],null,false,3325886106)}):_vm._e(),_c('a-empty',{directives:[{name:"show",rawName:"v-show",value:(!_setup.state.tableFinalData.length),expression:"!state.tableFinalData.length"}],staticClass:"mt10",attrs:{"description":"未能匹配到可使用的待排版库数据"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.isShowBox && !_setup.isExpand),expression:"isShowBox && !isExpand"}],staticClass:"bujian-block"})])
}
var staticRenderFns = []

export { render, staticRenderFns }