var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showPartDetail)?_c('div',{staticClass:"not-paiban-detail t0 l0 w100 h100",attrs:{"id":"material_not_paiban_detail_modal"}},[_c('div',{staticClass:"content relative r0 l0 bg-white br4 pb15"},[_c('div',{staticClass:"head flex flex-main--justify pb10"},[_c('div',[_c('i',{staticClass:"iconfont icon-left mr5 cursor-pointer",staticStyle:{"color":"#18a8c7","font-size":"12px"},attrs:{"id":"not_paiban_detail_back"},on:{"click":_vm.handleClosePartDetail}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('materialPage.paibanSetting.customPlankOrderDetail'))+" ")])]),_c('i',{staticClass:"iconfont icon-close cursor-pointer",staticStyle:{"font-size":"12px"},attrs:{"id":"not_paiban_detail_close"},on:{"click":_vm.handleClosePartDetail}})]),_c('a-tabs',{staticClass:"part-detail-tabs",attrs:{"id":"not_paiban_detail_tabs"},on:{"tabClick":_vm.handleTabClick},model:{value:(_vm.activePartTab),callback:function ($$v) {_vm.activePartTab=$$v},expression:"activePartTab"}},[_c('a-tab-pane',{key:"全部",attrs:{"tab":_vm.$t('common.all'),"id":"not_paiban_detail_all_tab"}}),_c('a-tab-pane',{key:"剩余小板清单",attrs:{"tab":_vm.$t('materialPage.paibanSetting.surplusPlank'),"id":"not_paiban_detail_surplus_tab"}}),_c('a-tab-pane',{key:"超尺板件清单",attrs:{"tab":_vm.$t('materialPage.paibanSetting.overSizePlank'),"id":"not_paiban_detail_overSize_tab"}})],1),_c('el-table',{staticClass:"not-paiban-detail-table",attrs:{"max-height":"560","header-cell-style":{
        backgroundColor: '#F3F3F3',
        height: '36px',
        padding: 0,
        color: 'rgba(0,0,0,0.6)',
        fontWeight: 400,
      },"header-row-style":{
        lineHeight: '36px',
      },"cell-style":{
        color: 'rgba(0,0,0,0.8)',
        padding: 0,
      },"row-style":{ height: '40px' },"data":_vm.allList}},_vm._l((_vm.partColumns),function(item){return _c('el-table-column',{key:item.prop,attrs:{"prop":item.prop,"label":['blankHeight', 'blankWidth', 'thick'].includes(item.prop)
            ? _vm.$t(`common.${item.transKey}`) + 'mm'
            : _vm.$t(`common.${item.transKey}`),"width":item.width,"show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row[item.prop]))])]}}],null,true)})}),1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }