import { genFinalRecord } from '@/util/commonFuncs.js'

import {
  doorOptions,
  highPlankOptions,
  texDirOptions,
} from '../config/commonConfig'
import type { IEditableFields } from '../types/material'

export type ChangePart = Array<{
  plankId: number
  partName: string
  address: string
}>
interface GenerateOptionRecordParamsType {
  prop: IEditableFields
  value: any
  oldValue?: any
  changeParts: ChangePart
  address: string
  textKey?: string
}
const operateArr: Record<string, any>[] = []
// 用于全局存储开料清单中的操作记录数据
export const useRecordData = () => {
  const addOperateRecord = (options: GenerateOptionRecordParamsType) => {
    const {
      prop,
      value,
      changeParts,
      oldValue,
      address,
      textKey = '批量修改',
    } = options
    // prop对应的中文
    const propMapText: Record<IEditableFields, string> = {
      matCode: '材质',
      roomName: '房间',
      thick: '厚度',
      texture: '颜色',
      specHeight: '板件长度',
      specWidth: '板件宽度',
      type: '门板',
      amount: '数量',
      is_high_gloss_plank: '高光板',
      leftEdge: '左封边',
      rightEdge: '右封边',
      backEdge: '后封边',
      frontEdge: '前封边',
      allEdge: '全部封边',
      loc: '柜体',
      texDir: '纹理',
      plank_remarks: '板件备注',
      orderNo: '订单号',
      address: '项目地址',
      customer_name: '客户',
      partName: '板件名称',
      remark: '房间备注',
    }
    // 特殊值映射
    const specialValueMap = new Map<string, Array<Record<string, any>>>([
      ['is_high_gloss_plank', highPlankOptions],
      ['texDir', texDirOptions],
      ['type', doorOptions],
    ])
    const valueText = specialValueMap.has(prop)
      ? specialValueMap.get(prop)?.find((item) => item.value === value)?.label
      : value
    const oldValueText = specialValueMap.has(prop)
      ? specialValueMap.get(prop)?.find((item) => item.value === oldValue)
          ?.label
      : oldValue || ''
    const record = {
      key: textKey,
      dataArr: [
        {
          type: 'form',
          // 当前表单数据
          current: {
            prop: valueText,
            address,
            changeParts,
          },
          // 原始表单数据
          ori: {
            prop: oldValueText,
            changeParts: [],
            address: '',
          },
          // 要比较的内容
          compareMsg: [
            {
              title: `修改的属性`,
              keys: ['prop'],
            },
            {
              title: '修改的板件',
              keys: ['changeParts'],
            },
            {
              title: '订单地址',
              keys: ['address'],
            },
          ],
          formTextObj: {
            prop: propMapText[prop],
            changeParts: '修改的板件',
            address: '订单地址',
          },
          formValueObj: {},
        },
      ],
    }
    operateArr.push(record)
  }

  // 保存操作记录
  const saveOperateRecord = () => {
    const recordData = { operateArr }
    // 上传文件
    genFinalRecord(recordData, `开料清单`, null, null)
    // 清空操作记录
    resetOperateRecord()
  }

  // 重置操作记录
  const resetOperateRecord = () => {
    operateArr.length = 0
  }

  return {
    operateArr,
    addOperateRecord,
    saveOperateRecord,
    resetOperateRecord,
  }
}
