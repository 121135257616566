<template>
  <div>
    <a-upload
      list-type="picture-card"
      @change="fileChange"
      :before-upload="beforeUpload"
      @preview="handlePreview"
      :file-list="fileList"
      :accept="acceptStr"
      :customRequest="customRequest"
    >
      <div v-if="flies.length < fileMaxNum">
        <a-icon type="plus" style="font-size: 16px" />
        <div class="ant-upload-text">上传照片</div>
      </div>
    </a-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      top="10vh"
      width="40%"
      append-to-body
      custom-class="previewImageDialog"
    >
      <span>
        <img alt="example" class="w100" :src="previewImage" />
      </span>
    </el-dialog>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  data() {
    return {
      flies: [],
      previewVisible: false,
      previewImage: '',
      dialogVisible: false,
      fileFlag: true,
      fileList: [],
    }
  },
  props: {
    accept: {
      type: Array,
      default: () => ['image/jpg', 'image/png', 'image/jpeg'],
    },
    // 单次最大可上传大小
    maxSize: {
      type: Number,
      default: 10,
    },
    // 最大可上传文件数量
    fileMaxNum: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    acceptStr() {
      let str = ''
      for (let v of this.accept) {
        if (v.includes('/')) {
          v = v.split('/')[1]
        }
        v = ',.' + v
        str += v
      }
      return str.substring(1)
    },
  },
  methods: {
    // 文件变化则将最新的文件数组发射出
    fileChange(e) {
      let { file, fileList } = e
      if (!this.fileFlag) {
        this.fileList = fileList.filter((f) => f.thumbUrl != file.thumbUrl)
        return
      }
      this.fileList = fileList

      const status = file.status
      if (status === 'error') return
      this.flies = []
      for (const f of fileList) {
        const o = f.originFileObj
        if (!this.flies.includes(o)) {
          this.flies.push(o)
        }
      }
      e.file.status = 'done'
      this.$emit('fileChange', this.flies)
    },
    // 文件上传前的验证
    beforeUpload(file) {
      const isJpgOrPng = this.accept.some((item) => item.includes(file.type))
      if (!isJpgOrPng) {
        this.$message.error('选择的文件格式不正确, 请删除重新上传')
      }
      const isLtSize = file.size / 1024 / 1024 < this.maxSize
      if (!isLtSize) {
        this.$message.error(`文件必须小于${this.maxSize}M`)
      }
      this.fileFlag = isJpgOrPng && isLtSize
      return isJpgOrPng && isLtSize
    },
    // 触发预览大图
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      if (!file.type.includes('image')) {
        this.$message.error('所选文件不是图片无法进行大图预览')
        return
      }
      this.previewImage = file.url || file.preview
      this.dialogVisible = true
    },
    // 覆盖默认上传 解除上传报错
    customRequest() {},
  },
}
</script>

<style lang="less">
.previewImageDialog .el-dialog__header {
  padding-bottom: 0px;
}
.previewImageDialog .el-dialog__body {
  padding-top: 20px;
}
</style>
