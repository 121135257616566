export const rules = {
  input: (row, list) => {
    let flag = true,
      msg = '请检查是否有错误输入'
    const { width, length, thick, texture, matCode, texDir } = row
    let result = list.filter(
      (item) =>
        +item.width == +width &&
        +item.length == +length &&
        item.thick == thick &&
        item.texture == texture &&
        item.matCode == matCode &&
        item.texDir == texDir
    )
    if (!width || !length) {
      flag = false
    }
    if (result.length >= 2) {
      flag = false
      msg = '存在相同模板'
    }
    return { flag, msg }
  },
}
