import { dealPlankListLabelID } from '@/util/dealPaibanData.js'
import { dealMaterialList } from '@/util/plankCommonFuncs'
import { cloneDeep } from 'lodash'

import type { IFilterableFieldsType } from '../types/material'
import type { PartType, PlankType } from '../types/part'

/**
 *
 * @param plankList : 原始料单展示数据
 * @param filterObj ： 筛选条件
 * @param searchCondition ：搜索条件（尺寸，板件名称，板号）
 * @returns
 */
export const dealFilterData = (
  plankList: Readonly<PlankType[]>,
  filterObj?: Partial<Record<IFilterableFieldsType, any[]>>,
  searchCondition?: {
    searchKey: string
    minSize: number | string
    maxSize: number | string
  }
) => {
  // 获取筛选项
  const filterKeys =
    filterObj && (Object.keys(filterObj) as IFilterableFieldsType[])
  // 对传入的plankList做拷贝，防止影响原数据
  // const copyPlankList = cloneDeep(plankList)
  let newPlankList = plankList
  // 如果存在筛选项
  if (filterObj && filterKeys && filterKeys.length) {
    newPlankList = plankList
      .map((plank) => {
        const parts = plank.parts.filter((part) => {
          const listArr: boolean[] = []
          filterKeys.forEach((key) => {
            if (key && filterObj[key]?.includes(part[key])) {
              listArr.push(true)
            } else {
              listArr.push(false)
            }
          })
          return listArr.every((item) => item)
        })
        return {
          ...plank,
          parts,
        }
      })
      .filter((plank) => plank.parts.length)
  }

  // 关键字搜索和尺寸搜索互斥
  if (searchCondition) {
    const { minSize, maxSize, searchKey } = searchCondition
    if (searchCondition.minSize && searchCondition.maxSize) {
      const newMinSize = Number(minSize)
      const newMaxSize = Number(maxSize)
      newPlankList = newPlankList
        .map((plank) => {
          return {
            ...plank,
            parts: plank.parts.filter((part) =>
              rangeSize(part, newMinSize, newMaxSize)
            ),
          }
        })
        .filter((plank) => plank.parts.length)
    }
    if (searchKey) {
      const newList: PlankType[] = []
      newPlankList.forEach((plank) => {
        const parts = plank.parts.filter((part) => {
          const f =
            part.partName.includes(searchKey) ||
            part.plankID == Number(searchKey) // 此处用==，因为plankID变为了string类型
          if (f) return true
          else {
            part.isSelected = true
          }
        })
        if (parts.length) newList.push({ ...plank, parts })
      })
      newPlankList = newList
    }
  }

  const result = dealPlankListLabelID(
    dealMaterialList(newPlankList as PlankType[])
  )

  return result as PlankType[]
}

// 判断板件尺寸是否在搜索范围内
const rangeSize = (part: PartType, minSize: number, maxSize: number) => {
  return (
    (part.specHeight <= Number(maxSize) &&
      part.specHeight >= Number(minSize)) ||
    (part.specWidth <= Number(maxSize) && part.specWidth >= Number(minSize))
  )
}
