export const samPleConfig = {
  labelWidth: '80px',
  inputSize: 'small',
  compsStyle: {
    width: '210px',
  },
  formOther: {
    labelPosition: 'left',
  },
  formItems: [
    {
      field: 'address',
      type: 'input',
      label: 'common.orderAddress',
      placeholder: 'common.orderAddress',
      disabled: true,
      otherOptions: {
        disabled: false,
      },
      tooltip: {
        disabled: true,
        content: '多订单暂不支持修改项目地址',
        // content: moreSampleFormData.address,
        placement: 'top',
      },
    },
    {
      field: 'clientData',
      type: 'input',
      label: 'common.customerInfo',
      placeholder: 'common.customerInfo',
      disabled: true,
      otherOptions: {
        disabled: false,
      },
      tooltip: {
        disabled: true,
        content: 'common.customerEditErr',
        placement: 'top',
      },
    },
    {
      field: 'factoryOrderNum',
      type: 'input',
      label: 'common.factoryOrderNo',
      placeholder: 'common.factoryOrderNo',
      disabled: true,
      otherOptions: {
        disabled: false,
      },
      tooltip: {
        disabled: true,
        content: 'common.factoryEditErr',
        // content: moreSampleFormData.factoryOrderNum,
        placement: 'top',
      },
    },
    {
      field: 'roomName',
      type: 'input',
      label: 'common.roomName',
      placeholder: 'common.roomName',
      disabled: true,
      otherOptions: {
        disabled: false,
      },
      tooltip: {
        disabled: true,
        content: 'common.roomNameEditErr',
        placement: 'top',
      },
    },
    {
      field: 'orderRemark',
      type: 'input',
      label: 'printPage.editPage.orderRemark',
      placeholder: 'printPage.editPage.orderRemark',
      disabled: true,
      otherOptions: {
        disabled: false,
      },
      tooltip: {
        disabled: true,
        content: 'common.remarkEditErr',
        placement: 'top',
      },
    },
  ],
}
