import { httpRequest } from '@/apis/request'

enum Apis {
  SaveMaterialData = 'save_material_data',
}
/**
 * @description 保存历史
 * @param data
 */
export function saveHistory(data: any) {
  return httpRequest.post(Apis.SaveMaterialData, data)
}
