import { languageMap } from './languageMap'

export const tableHeaderMappingList = {
  [languageMap.get('板号')!]: 'plankID',
  [languageMap.get('材质')!]: 'matCode',
  [languageMap.get('颜色')!]: 'texture',
  [languageMap.get('板件名称')!]: 'partName',
  [languageMap.get('成品长度')!]: 'specHeight',
  [languageMap.get('成品宽度')!]: 'specWidth',
  [languageMap.get('数量')!]: 'amount',
  [languageMap.get('面积')!]: 'area',
  [languageMap.get('左封边')!]: 'leftEdge',
  [languageMap.get('右封边')!]: 'rightEdge',
  [languageMap.get('前封边')!]: 'frontEdge',
  [languageMap.get('后封边')!]: 'backEdge',
  [languageMap.get('纹理')!]: 'texDir',
  [languageMap.get('厚度')!]: 'thick',
  [languageMap.get('项目地址')!]: 'address',
  [languageMap.get('板件条码')!]: 'oriPlankNum',
  [languageMap.get('客户')!]: 'customer_name',
  [languageMap.get('房间')!]: 'roomName',
  [languageMap.get('订单号')!]: 'orderNo',
  [languageMap.get('柜体')!]: 'loc',
  [languageMap.get('孔槽数量')!]: 'hsInfo',
  [languageMap.get('异形')!]: 'specialShape',
  [languageMap.get('门板')!]: 'type',
  [languageMap.get('板件备注')!]: 'plank_remarks',
  [languageMap.get('房间备注')!]: 'remark',
  // 新增导出csv高光板字段
  [languageMap.get('高光板')!]: 'is_high_gloss_plank',
  // 额外的中文处理，用于部分极限情况下翻译未正常处理，导致无法正常匹配(对应的添加字段都需要两套)
  ['板号']: 'plankID',
  ['材质']: 'matCode',
  ['颜色']: 'texture',
  ['板件名称']: 'partName',
  ['成品长度']: 'specHeight',
  ['成品宽度']: 'specWidth',
  ['数量']: 'amount',
  ['面积']: 'area',
  ['左封边']: 'leftEdge',
  ['右封边']: 'rightEdge',
  ['前封边']: 'frontEdge',
  ['后封边']: 'backEdge',
  ['纹理']: 'texDir',
  ['厚度']: 'thick',
  ['项目地址']: 'address',
  ['板件条码']: 'oriPlankNum',
  ['客户']: 'customer_name',
  ['房间']: 'roomName',
  ['订单号']: 'orderNo',
  ['柜体']: 'loc',
  ['孔槽数量']: 'hsInfo',
  ['异形']: 'specialShape',
  ['门板']: 'type',
  ['板件备注']: 'plank_remarks',
  ['房间备注']: 'remark',
  ['高光板']: 'is_high_gloss_plank',
} as const
