<template>
  <div>
    <el-drawer
      title="我是标题"
      :visible.sync="visible"
      :with-header="false"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      :before-close="close"
    >
      <div class="main">
        <div class="plank">
          <drawSupplus
            canvasKey="rect"
            :size="drawObj"
            :drawScale="1"
            defaultRectText="裁剪"
          ></drawSupplus>
        </div>
        <div class="tip">
          <span>基本信息</span>
        </div>
        <div>
          <el-form
            label-position="left"
            label-width="90px"
            :model="addFormData"
          >
            <el-form-item label="裁剪宽度:">
              <el-input
                v-model="addFormData.width"
                size="mini"
                @input="handleInputVal('addFormData', 'width')"
                id="surplus_temp_width_input"
              >
                <template slot="append">mm</template>
              </el-input>
            </el-form-item>
            <el-form-item label="裁剪长度:">
              <el-input
                v-model="addFormData.length"
                size="mini"
                @input="handleInputVal('addFormData', 'length')"
                id="surplus_temp_length_input"
              >
                <template slot="append">mm</template>
              </el-input>
            </el-form-item>
            <el-form-item label="板材厚度:">
              <el-input
                v-model="addFormData.thick"
                size="mini"
                @input="handleInputVal('addFormData', 'thick')"
                id="surplus_temp_thick_input"
              >
                <template slot="append">mm</template>
              </el-input>
            </el-form-item>
            <el-form-item label="材质:">
              <el-input
                v-model="addFormData.matCode"
                size="mini"
                id="surplus_temp_matCode_input"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="颜色:">
              <el-input
                v-model="addFormData.texture"
                size="mini"
                id="surplus_temp_texture_input"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="纹理:">
              <el-select
                v-model="addFormData.texDir"
                placeholder="请选择"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in texDirOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :id="`surplus_temp_texDir_option_${index}`"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="handle">
          <a-button @click="close" id="surplus_temp_cancel_btn">取消</a-button>
          <a-button
            @click="addSurPlusTemp(true)"
            v-show="!isEdit"
            id="surplus_temp_confirm_continue_btn"
            >确定并继续</a-button
          >
          <a-button
            @click="addSurPlusTemp(false)"
            style="
              background: #18a8c7;
              color: #fff;
              width: 120px;
              margin-right: 8px;
            "
            id="surplus_temp_confirm_btn"
            >{{ isEdit ? '确定修改' : '确定' }}</a-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import drawSupplus from '@/components/draw/drawSupplus.vue'

export default {
  components: {
    drawSupplus,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 添加模板对象
      addFormData: {
        width: '',
        length: '',
        texDir: 'notcare',
        matCode: '',
        texture: '',
        thick: '',
      },
      drawObj: {
        width: 172,
        long: 257,
        min_width: 0,
        min_long: 0,
      },
      texDirOptions: [
        { label: '横纹', value: 'reverse' },
        { label: '竖纹', value: 'normal' },
        { label: '无纹理', value: 'notcare' },
      ],
    }
  },
  watch: {
    editData(n) {
      this.addFormData = JSON.parse(JSON.stringify(n))
    },
  },
  methods: {
    // 添加模板
    addSurPlusTemp(next) {
      if (!this.addFormData.width || !this.addFormData.length) {
        return this.$message.error('有必填项未填写')
      }
      if (
        String(this.addFormData.width).endsWith('.') ||
        String(this.addFormData.length).endsWith('.')
      ) {
        return this.$message.error('输入错误，请检查!')
      }
      this.addFormData.width = +this.addFormData.width
      this.addFormData.length = +this.addFormData.length
      this.addFormData.thick = this.addFormData.thick
        ? +this.addFormData.thick
        : this.addFormData.thick
      const oldData = JSON.parse(JSON.stringify(this.addFormData))
      this.$token('/save_surplus_model', this.addFormData, (res) => {
        if (res.status != 1) {
          this.$message.error(res.data)
        } else {
          const d = { ...oldData, id: res.data.id ?? oldData.id }
          this.$emit('addTemp', { data: d, res })
          this.$message.success('保存成功!')
        }
      })
      this.addFormData = {
        width: '',
        length: '',
        texDir: 'notcare',
        matCode: '',
        texture: '',
        thick: '',
      }
      if (next) {
        return
      }
      this.$emit('update:visible', false)
    },
    close() {
      this.$emit('update:visible', false)
    },
    // 数据验证
    /**
     * 前两个数据验证增加 后两个数据验证页面上修改  使用是对应的不为null就行
     */
    handleInputVal(obj, key, item, itemKey, isMinus = false) {
      const Reg = isMinus
        ? /(^\-?)\D*(\d{0,5})\d*(\.?)(\d{0,2})\d*/
        : /\D*(\d{0,5})\d*(\.?)(\d{0,2})\d*/
      const repStr = isMinus ? '$1$2$3$4' : '$1$2$3'
      if (item) {
        item[itemKey] = item[itemKey]
          .replace(/\.+?/, '(')
          .replace(/\./g, '')
          .replace(/\(/, '.')
          .replace(Reg, repStr)
          .replace(/[^\d\.\-]/, '')
      } else {
        this[obj][key] = this[obj][key]
          .replace(/\.+?/, '(')
          .replace(/\./g, '')
          .replace(/\(/, '.')
          .replace(Reg, repStr)
          .replace(/[^\d\.\-]/, '')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.el-drawer {
  .main {
    width: 100%;
    height: 100%;
    padding: 25px 15px;
  }

  .plank {
    text-align: center;
  }
}

.tip {
  height: 40px;
  line-height: 40px;
  border-left: 2px solid rgb(24, 168, 199);
  padding-left: 15px;
  background-color: rgb(250, 250, 250);
  margin-bottom: 20px;
}

.handle {
  width: 100%;
  bottom: 25px;
  display: flex;
  justify-content: space-evenly;
}
</style>
