// 创建requestIdleCallback的工厂函数
// export const createIdleCallback = (callback: Function) => {}

// const callback = () => {
//   console.log('这是回调函数')
// }

// const createdPdfTask: IdleRequestCallback = (deadline) => {
//   console.log('1111', index, deadline.timeRemaining())
//   if (deadline.timeRemaining() <= 10) {
//     requestIdleCallback(createdPdfTask)
//   } else {
//     callback()
//   }
// }
// // 使用requestIdleCallback,处理下载时，页面卡顿的问题
// requestIdleCallback(createdPdfTask)

// 传入一个任务函数，返回一个函数，这个函数会在浏览器空闲时执行任务函数
// export const createIdleCallback = (callback: IdleRequestCallback) => {
//   return () => {
//     requestIdleCallback(callback)
//   }
// }
/// <inference types="/src/shims-vue.d.ts" />

// 组合函数
type Func<T extends any[], R> = (...a: T) => R

export const compose = <P extends any[], R>(
  ...funcs: Func<any, R>[]
): Func<P, R> => {
  if (funcs.length === 0) {
    return (...arg: any[]) => arg as any
  }
  if (funcs.length === 1) {
    return funcs[0]
  }
  return funcs.reduce(
    (a, b) =>
      (...args: Parameters<typeof b>) =>
        a(b(...(args as ReturnType<typeof b>[])))
  )
}
