import { httpRequest } from '@/apis/request'

import type { GetSurplusListRes, GetSurplusParams } from './type'

enum Apis {
  batchSaveSurplusWarehouses = 'surplus/branch_warehouses',
  surplusTransfer = 'surplus/transfer',
  SurplusList = 'get_surplus_depot',
  getSurplusList = 'search_surplus_depot',
  surplusLock = 'surplus_lock',
}

interface surplusWarehouses {
  name: string
  capacity: number
  remark: string
  branch_no?: string
}

interface batchSaveSW {
  surplus_branch_warehouses: Array<surplusWarehouses>
  create_or_update: boolean
}

/**
 * 批量保存余料分仓
 * @param params 参数
 */
export function batchSaveSurWar(params: batchSaveSW) {
  return httpRequest.post(Apis.batchSaveSurplusWarehouses, params)
}

/**
 * 余料转移
 * @param params
 */
export function surplusTransfer(params: {
  roll_out_surplus_ids: number[]
  roll_out_ggids: string[]
  roll_in_branch_no: string
}) {
  return httpRequest.post(Apis.surplusTransfer, params)
}

export function getSurplusList(params: { limit: number; page: number }) {
  return httpRequest.get(Apis.SurplusList, params)
}

/**
 * 余料转移
 * @param params
 */
export function getSurplusListApi(params: GetSurplusParams) {
  return httpRequest.post<GetSurplusListRes>(Apis.getSurplusList, params)
}
/**
 * 余料锁定/解锁
 * @param params
 * @returns
 */
export function surplusLock(params: any) {
  return httpRequest.post(Apis.surplusLock, params)
}
