import type { IPropList } from '@/components/materialTable/src/config/tableConfig'
import { translate } from '@/util/commonFun'
import { languageMap } from '@/views/materialsList/config/languageMap'

const surPlusConfig = {
  propList: [
    {
      prop: 'width',
      label: 'common.width',
      minWidth: 100,
      slotName: 'width',
      interactionType: 'input',
      maxWidth: '150px',
    },
    {
      prop: 'length',
      label: 'common.long',
      minWidth: 100,
      slotName: 'height',
      interactionType: 'input',
      maxWidth: '150px',
    },
    {
      prop: 'texDir',
      label: 'common.texture',
      minWidth: 120,
      maxWidth: '150px',
      slotName: 'texDir',
      interactionType: 'select',
      selectOptions: [
        { label: 'common.reverse', value: 'reverse' },
        { label: 'common.normal', value: 'normal' },
        { label: 'common.notCare', value: 'notcare' },
      ],
    },
    {
      prop: 'thick',
      label: 'common.thick',
      minWidth: 80,
      slotName: 'thick',
      maxWidth: '150px',
      interactionType: 'input',
    },
    {
      prop: 'matCode',
      label: 'common.matCode',
      minWidth: 100,
      slotName: 'matCode',
      maxWidth: '150px',
      interactionType: 'input',
    },
    {
      prop: 'texture',
      label: 'common.color',
      minWidth: 100,
      maxWidth: '150px',
      slotName: 'texture',
      interactionType: 'input',
    },
  ],
  showIndexColumn: false,
  showSelection: true,
  selectionDirection: 'bottom',
  'max-height': '300px',
  maxWidth: '150px',
  size: 'mini',
}

const specialPlankList: IPropList[] = [
  {
    prop: 'select',
    headSlotName: 'headSelect',
    label: 'common.select',
    widthType: 'width',
    width: '64px',
    minWidth: '64px',

    maxWidth: '150px',
  },
  {
    prop: 'matCode',
    headSlotName: 'headMatCode',
    label: 'common.plankMatCode',
    widthType: 'width',
    width: '264px',
    minWidth: '64px',
    maxWidth: '150px',
    interactionType: 'input',
    verify: true,
  },
  {
    prop: 'color',
    headSlotName: 'headColor',
    label: 'common.plankColor',
    widthType: 'width',
    width: '164px',
    minWidth: '64px',
    maxWidth: '150px',
    interactionType: 'input',
    verify: true,
  },
  {
    prop: 'thick',
    headSlotName: 'headThick',
    label: 'common.plankThick',
    widthType: 'width',
    width: '164px',
    minWidth: '64px',
    maxWidth: '150px',
    interactionType: 'input',
    verify: true,
  },
  {
    prop: 'width',
    headSlotName: 'headWidth',
    label: 'common.plankWidth',
    widthType: 'width',
    width: '164px',
    minWidth: '64px',
    maxWidth: '150px',
    interactionType: 'input',
    verify: true,
  },

  {
    prop: 'height',
    headSlotName: 'headHeight',
    label: 'common.plankHeight',
    widthType: 'width',
    width: '164px',
    minWidth: '64px',
    maxWidth: '150px',
    interactionType: 'input',
    verify: true,
  },
  {
    prop: 'is_high_lank',
    headSlotName: 'headIsHighLank',
    label: 'common.heighGlossPlank',
    widthType: 'width',
    width: '264px',
    minWidth: '64px',
    maxWidth: '150px',
  },
  {
    prop: 'isPicked',
    headSlotName: 'headIsPicked',
    label: 'basePlankManager.defaultUse',
    widthType: 'width',
    width: '164px',
    minWidth: '64px',
    maxWidth: '150px',
  },
  {
    prop: 'operate',
    headSlotName: 'headOperate',
    label: 'common.operation',
    widthType: 'width',
    width: '164px',
    minWidth: '64px',
    maxWidth: '150px',
  },
]

const bujianManageTableConfig: IPropList[] = [
  {
    prop: 'select',
    selectProp: 'isSelected',
    headSlotName: 'headSelect',
    label: 'common.select',
    widthType: 'width',
    width: '64px',
    minWidth: '64px',
    maxWidth: '150px',
  },
  {
    prop: 'address',
    headSlotName: 'headAddress',
    label: 'common.orderAddress',
    widthType: 'width',
    width: '326px',
    minWidth: '30px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'customer_name',
    headSlotName: 'headCustomerName',
    label: 'common.customer',
    widthType: 'width',
    width: '189px',
    minWidth: '100px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'plankID',
    headSlotName: 'headPlankID',
    label: 'common.plankNo',
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'matCode',
    headSlotName: 'headMatCode',
    label: 'common.matCode',
    widthType: 'width',
    width: '226px',
    minWidth: '60px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'texture',
    headSlotName: 'headTexture',
    label: 'common.color',
    widthType: 'width',
    width: '110px',
    minWidth: '60px',
    maxWidth: '150px',
    tooltip: true,
  },
  {
    prop: 'size',
    headSlotName: 'headSize',
    label: 'materialPage.plankSize',
    widthType: 'width',
    maxWidth: '150px',
    width: '178px',
    minWidth: '30px',
  },
  {
    prop: 'plankNum',
    headSlotName: 'headPlankNum',
    label: 'common.plankNum',
    widthType: 'width',
    maxWidth: '150px',
    width: '226px',
    minWidth: '60px',
    verify: true,
  },
  {
    prop: 'create_time',
    headSlotName: 'headSaveTime',
    label: 'taskPage.record.saveDate',
    widthType: 'width',
    width: '126px',
    minWidth: '60px',
    maxWidth: '150px',
    verify: true,
  },
]

// 超尺小板表格数据
const extraPartsTableConfig = [
  {
    label: '订单号',
    prop: 'orderNo',
    width: '180',
  },
  {
    label: '项目地址',
    prop: 'address',
    width: '180',
  },
  {
    label: '房间名',
    prop: 'roomName',
    width: '180',
  },
  {
    label: '板件名称',
    prop: 'partName',
    width: '180',
  },
  {
    label: '板件材质',
    prop: 'matCode',
    width: '180',
  },
  {
    label: '板件颜色',
    prop: 'texture',
    width: '180',
  },
  {
    label: '下料长度',
    prop: 'blankHeight',
    width: '120',
  },
  {
    label: '下料宽度',
    prop: 'blankWidth',
    width: '120',
  },
  {
    label: '厚度',
    prop: 'thick',
    width: '180',
  },
  { label: '纹理', prop: 'texDir' },
  {
    label: '标签条码',
    prop: 'plankNum',
    width: '180',
  },
  { label: '板号', prop: 'plankID', width: '180' },
]

export {
  surPlusConfig,
  specialPlankList,
  bujianManageTableConfig,
  extraPartsTableConfig,
}
