import { message } from 'ant-design-vue'
import JsPdf from 'jspdf'

import { fontFamilyMap } from './pdf-draw'

type FontInfo = {
  url: string
  // 此字体是否已加载过
  isLoad: boolean
}
type FontKey = 'msyh' | 'simsun' | 'simhei'
type FontMap = Map<FontKey, FontInfo>

/** 字体存储位置 */
const fontToOss: FontMap = new Map([
  // 微软雅黑
  [
    'msyh',
    {
      url: 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/cloud_paiban/print_pdf_font/msyh.text',
      isLoad: false,
    },
  ],
  //   宋体
  [
    'simsun',
    {
      url: 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/cloud_paiban/print_pdf_font/simsun.text',
      isLoad: false,
    },
  ],
  //   黑体
  [
    'simhei',
    {
      url: 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/cloud_paiban/print_pdf_font/simhei.text',
      isLoad: false,
    },
  ],
])
/** 整合一些需要处理的jspdf工具函数 */
class JsPdfTool {
  // 单例
  static instance: JsPdfTool
  font!: typeof fontToOss
  constructor() {
    if (JsPdfTool.instance) {
      return JsPdfTool.instance
    }
    JsPdfTool.instance = this
    this.font = fontToOss
  }
  /** 加载单个字体 */
  async loadFont(fontKey: FontKey) {
    try {
      let target = this.font.get(fontKey)
      // 不存在则默认使用微软雅黑
      if (!target) {
        target = this.font.get('msyh')!
        fontKey = 'msyh'
      }
      if (target.isLoad) return
      const result = await fetch(target.url).then((r) => r.text())
      const callAddFont = function (this: JsPdf) {
        this.addFileToVFS(`${fontKey}-normal.ttf`, result)
        this.addFont(`${fontKey}-normal.ttf`, fontKey, 'normal')
      }
      JsPdf.API.events.push(['addFonts', callAddFont])
      // 完成加载并记录
      target.isLoad = true
    } catch (error) {
      message.error(`${fontKey}字体加载失败,pdf文件内容可能存在损坏,请刷新重试`)
    }
  }
  async setFont(instance: JsPdf, fontKey: FontKey) {
    await this.loadFont(fontKey)
    instance.setFont(fontKey)
  }
  /** 加载标签模版内的所有字体文件 */
  async loadFontByTagTemp(
    temp: { data: { fontFamily: keyof typeof fontFamilyMap } }[]
  ) {
    const fonts = new Set<FontKey>()
    // 从模版中拿到所需要的所有字体统一注册
    temp.forEach((item) => {
      const font = (fontFamilyMap[item.data?.fontFamily] as FontKey) || 'msyh'
      fonts.add(font)
    })
    const promise = [...fonts].map((k) => this.loadFont(k))
    await Promise.all(promise)
  }
}

export const jsPdfTool = new JsPdfTool()
