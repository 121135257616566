<template>
  <div>
    <canvas
      :id="`canvas_${canvasKey}`"
      :ref="`canvas_${canvasKey}`"
      :width="canvasWidth"
      :height="size.long / this.drawScale + 88"
    ></canvas>
  </div>
</template>

<script>
import { dealSupplusPoints } from '../../util/dealSupplusPoints.js'

export default {
  props: {
    canvasKey: {
      type: [String, Boolean],
    },
    size: {
      type: Object,
      default: () => {},
    },
    drawScale: {
      type: Number,
      default: 1,
    },
    isNewSurplus: {
      type: Boolean,
      default: false,
    },
    defaultRectText: {
      type: String,
      default: '板件',
    },
  },
  data() {
    return {
      canvas: {},
    }
  },
  watch: {
    canvasKey() {
      this.$nextTick(() => {
        this.canvas.clearRect(0, 0, 400, 400)
        this.draw()
      })
    },
  },
  computed: {
    canvasWidth() {
      let width =
        this.size.width / this.drawScale + (this.isNewSurplus ? 140 : 120)
      if (this.canvasKey == 'L') {
        width += 40
      }
      if (!this.canvasKey) {
        if (this.size.min_long != 0) {
          width += 50
        }
      }
      return this.defaultRectText ? width + 10 : width
    },
  },
  methods: {
    draw() {
      let dom = this.$refs[`canvas_${this.canvasKey}`]
      let ctx = dom.getContext('2d')
      this.canvas = ctx
      ctx.fillStyle = '#ECCEA0'
      ctx.strokeStyle = '#CCCCCC'
      ctx.font = `12px "PingFangSC-Regular, PingFang SC"`
      if (this.canvasKey == 'rect') {
        let obj = dealSupplusPoints(
          this.defaultRectText,
          this.size,
          this.drawScale
        )
        this.drawRect(ctx, obj)
      } else if (this.canvasKey == 'L') {
        let obj = dealSupplusPoints('L', this.size, this.drawScale)
        this.drawLShape(ctx, obj)
      } else {
        if (this.size.min_long == 0) {
          let obj = dealSupplusPoints(false, this.size, this.drawScale)
          this.drawRect(ctx, obj)
        } else {
          let obj = dealSupplusPoints(false, this.size, this.drawScale)
          this.drawLShape(ctx, obj)
        }
      }
    },
    // 绘制矩形余料
    drawRect(ctx, obj) {
      ctx.beginPath()
      for (let i = 0; i < obj.shape.length; ++i) {
        let point = obj.shape[i]
        if (i == 0) {
          ctx.moveTo(point.x + 30, point.y + 30)
        } else {
          ctx.lineTo(point.x + 30, point.y + 30)
        }
      }
      ctx.closePath()
      ctx.fill()

      // 绘制标尺线
      ctx.beginPath()
      ctx.fillStyle = '#333'
      for (let i = 0; i < obj.plankW.line.length; ++i) {
        let point = obj.plankW.line[i]
        if (i % 2 == 0) {
          ctx.moveTo(
            point.x + 30,
            point.y + this.size.long / this.drawScale + 42
          )
        } else {
          ctx.lineTo(
            point.x + 30,
            point.y + this.size.long / this.drawScale + 42
          )
        }
      }
      ctx.fillText(
        obj.plankW.text.str,
        obj.plankW.text.x + 30,
        obj.plankW.text.y + 65 + this.size.long / this.drawScale
      )
      for (let i = 0; i < obj.plankH.line.length; ++i) {
        let point = obj.plankH.line[i]
        if (i % 2 == 0) {
          ctx.moveTo(
            point.x + this.size.width / this.drawScale + 42,
            point.y + 30
          )
        } else {
          ctx.lineTo(
            point.x + this.size.width / this.drawScale + 42,
            point.y + 30
          )
        }
      }
      ctx.fillText(
        obj.plankH.text.str,
        obj.plankH.text.x + this.size.width / this.drawScale + 55,
        obj.plankH.text.y + 30
      )
      ctx.stroke()
    },
    // 绘制L形余料
    drawLShape(ctx, obj) {
      ctx.beginPath()
      let extraOffset = 35
      for (let i = 0; i < obj.shape.length; ++i) {
        let point = obj.shape[i]
        if (i == 0) {
          ctx.moveTo(point.x + 50 + extraOffset, point.y + 50)
        } else {
          ctx.lineTo(point.x + 50 + extraOffset, point.y + 50)
        }
      }
      ctx.closePath()
      ctx.fill()

      // 绘制标尺线
      // 板件宽度
      ctx.beginPath()
      ctx.fillStyle = '#333'
      for (let i = 0; i < obj.plankW.line.length; ++i) {
        let point = obj.plankW.line[i]
        if (i % 2 == 0) {
          ctx.moveTo(
            point.x + 50 + extraOffset,
            point.y + this.size.long / this.drawScale + 62
          )
        } else {
          ctx.lineTo(
            point.x + 50 + extraOffset,
            point.y + this.size.long / this.drawScale + 62
          )
        }
      }
      ctx.fillText(
        obj.plankW.text.str,
        obj.plankW.text.x + 50 + extraOffset,
        obj.plankW.text.y + 85 + this.size.long / this.drawScale
      )

      // 板件长度
      for (let i = 0; i < obj.plankH.line.length; ++i) {
        let point = obj.plankH.line[i]
        if (i % 2 == 0) {
          ctx.moveTo(point.x + 20 + extraOffset, point.y + 51)
        } else {
          ctx.lineTo(point.x + 20 + extraOffset, point.y + 51)
        }
      }
      ctx.fillText(
        obj.plankH.text.str,
        obj.plankH.text.x,
        obj.plankH.text.y + 50
      )

      // 板件短边宽度
      for (let i = 0; i < obj.plankMinW?.line.length; ++i) {
        let point = obj.plankMinW.line[i]
        if (i % 2 == 0) {
          ctx.moveTo(point.x + 50 + extraOffset, point.y + 20)
        } else {
          ctx.lineTo(point.x + 50 + extraOffset, point.y + 20)
        }
      }
      ctx.fillText(
        obj.plankMinW?.text.str,
        obj.plankMinW?.text.x + 50 + extraOffset,
        obj.plankMinW?.text.y + 20
      )

      // 板件短边长度
      for (let i = 0; i < obj.plankMinH?.line.length; ++i) {
        let point = obj.plankMinH.line[i]
        if (i % 2 == 0) {
          ctx.moveTo(
            point.x + this.size.width / this.drawScale + 60 + extraOffset,
            point.y +
              (this.size.long - this.size.min_long) / this.drawScale +
              50
          )
        } else {
          ctx.lineTo(
            point.x + this.size.width / this.drawScale + 60 + extraOffset,
            point.y +
              (this.size.long - this.size.min_long) / this.drawScale +
              50
          )
        }
      }
      ctx.fillText(
        obj.plankMinH?.text.str,
        obj.plankMinH?.text.x +
          this.size.width / this.drawScale +
          80 +
          extraOffset,
        obj.plankMinH?.text.y +
          (this.size.long - this.size.min_long) / this.drawScale +
          51
      )

      ctx.stroke()
    },
  },
  mounted() {
    this.draw()
  },
}
</script>

<style scoped></style>
