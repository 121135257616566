<template>
  <div id="form">
    <el-form
      :model="formData"
      status-icon
      ref="form"
      :label-width="labelWidth"
      :rules="rules"
      :inline="true"
      v-bind="formOther"
    >
      <el-row>
        <template v-for="item in formItems">
          <el-col v-bind="colLayout" :key="item.field">
            <el-form-item
              v-if="item.field != 'opening_hours'"
              v-bind="formItemOther"
              :label="translateLang(item.label)"
              :style="itemStyle"
              :class="`Item${item.field}`"
              :prop="item.field"
            >
              <!-- input -->
              <template
                v-if="item.type === 'input' || item.type === 'textarea'"
              >
                <el-tooltip
                  v-bind="item.tooltip"
                  :disabled="item.tooltip?.disabled ?? true"
                >
                  <el-input
                    :type="item.type"
                    :placeholder="translateLang(item.placeholder)"
                    v-bind="item.otherOptions"
                    :show-word-limit="
                      item.otherOptions && !!item.otherOptions.maxlength
                    "
                    :style="compsStyle"
                    :size="inputSize"
                    v-model="formData[`${item.field}`]"
                    :disabled="item.disabled"
                    :id="`${item.field}_input`"
                    @input="handleChangeInput(item.field)"
                  >
                  </el-input>
                </el-tooltip>
              </template>
              <!-- select -->
              <template v-else-if="item.type === 'select'">
                <el-tooltip
                  v-bind="item.tooltip"
                  :disabled="item.tooltip?.disabled ?? true"
                >
                  <el-select
                    :placeholder="translateLang(item.placeholder)"
                    v-bind="item.otherOptions"
                    :size="inputSize"
                    clearable
                    :style="compsStyle"
                    v-model="formData[`${item.field}`]"
                    :id="`${item.field}_select`"
                  >
                    <el-option
                      v-for="option in item.options"
                      :key="option.value"
                      :value="option.value"
                      :label="translateLang(option.label)"
                      :id="`${item.field}_select_${option.value}`"
                      >{{ translateLang(option.label) }}
                    </el-option>
                  </el-select>
                </el-tooltip>
              </template>
              <!-- cascader -->
              <template v-else-if="item.type === 'cascader'">
                <el-tooltip
                  v-bind="item.tooltip"
                  :disabled="item.tooltip?.disabled ?? true"
                  ><el-cascader
                    v-model="formData[`${item.field}`]"
                    :size="inputSize"
                    :options="item.cascaderOptions"
                    clearable
                    :style="compsStyle"
                    v-bind="item.otherOptions"
                    :id="`${item.field}_cascader`"
                  >
                  </el-cascader>
                </el-tooltip>
              </template>
              <!-- 上传 -->
              <template v-else-if="item.type === 'upload'">
                <el-tooltip
                  v-bind="item.tooltip"
                  :disabled="item.tooltip?.disabled ?? true"
                >
                  <upload-file
                    @fileChange="fileChange($event, item.field)"
                  /> </el-tooltip
                ><span>{{
                  item.otherOptions && item.otherOptions.uploadHint
                    ? item.otherOptions.uploadHint
                    : ''
                }}</span>
              </template>

              <!-- 多选框 -->
              <template v-if="item.type === 'checkbox'">
                <a-checkbox-group
                  v-model="formData[`${item.field}`]"
                  @change="handleChangeCheckbox"
                >
                  <a-row>
                    <a-col :span="25">
                      <a-checkbox
                        v-for="option in item.options"
                        :key="option.value"
                        :value="option.value"
                        :label="option.label"
                      >
                        {{ option.value }}
                      </a-checkbox>
                      <p v-if="hasOtherEquipment">
                        <el-input
                          show-word-limit
                          maxlength="100"
                          type="textarea"
                          v-model="formData.other_equipment"
                        >
                        </el-input>
                      </p>
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </template>
            </el-form-item>
            <el-form-item
              v-else
              v-bind="formItemOther"
              :label="translateLang(item.label)"
              :style="itemStyle"
              :class="`Item${item.field}`"
              required
            >
              <!-- 时间 -->
              <div class="relative">
                <template v-if="item.type === 'opening_hours'">
                  <el-form-item prop="opening_hours" class="mr0">
                    <el-time-select
                      placeholder="开始时间"
                      v-model="formData.opening_hours"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59',
                      }"
                    />
                  </el-form-item>
                  一
                  <el-form-item prop="closing_time">
                    <el-time-select
                      v-model="formData.closing_time"
                      placeholder="结束时间"
                      :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59',
                      }"
                    />
                  </el-form-item>
                </template>
              </div>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
  </div>
</template>

<script>
/**
 * 由外部提供配置对象，生成表单对象
 * formData -> 原始数据对象
 * formItem -> {
 *  field 绑定到formData对象的字段
 *  type 生成组件的类型
 *  label -> label
 *  placeholder -> placeholder
 *  options 类型为select作为选项
 *  cascaderOptions 类型为cascader作为选项
 *  otherOptions 组件上的其他属性
 * }
 *
 * rules -> 表单验证对象
 *
 */
import uploadFile from '@/components/uploadFile.vue'
import { translate } from '@/util/commonFun'

export default {
  props: {
    // 原始数据
    formData: {
      type: Object,
      default: () => ({ other_equipment: '' }),
      required: true,
    },
    // formItem的配置
    formItems: {
      type: Array,
      default: () => [],
    },
    // label宽度  默认70
    labelWidth: {
      type: String,
      default: '70px',
    },
    // item样式
    itemStyle: {
      type: Object,
      default: () => ({}),
    },
    // 每个item内部组件样式
    compsStyle: {
      type: Object,
      default: () => ({
        width: '346px',
      }),
    },
    // input类型框体大小
    inputSize: {
      type: String,
      default: 'small',
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
    // layout布局
    colLayout: {
      type: Object,
      default: () => ({
        xl: 6, // >1920px 4个
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24,
      }),
    },
    formOther: {
      type: Object,
      default: () => ({}),
    },
    formItemOther: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timeRules: {
        closing_time: [
          { required: true, message: '请输入信息', trigger: 'blur' },
        ],
        opening_hours: [
          { required: false, message: '请输入信息', trigger: 'blur' },
        ],
      },
    }
  },
  components: { uploadFile },
  watch: {
    formData: {
      handler(n) {
        this.$emit('update:formData', n)
      },
      deep: true,
    },
  },
  methods: {
    translateLang(key) {
      return translate(key)
    },
    fileChange(e, field) {
      this.formData[field] = [...e]
    },
    validate(cb) {
      this.$refs['form'].validate((valid) => {
        cb && cb(valid)
      })
    },
    handleChangeCheckbox() {
      this.$refs['form'].validateField('equipment_repair')
    },
    handleChangeInput(val) {
      this.formData[val] = this.formData[val].replaceAll(' ', '')
    },
  },
  computed: {
    hasOtherEquipment() {
      let equipmentRepair = this.formData.equipment_repair
      if (
        equipmentRepair &&
        equipmentRepair.length &&
        equipmentRepair.includes('其他设备')
      ) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="less" scoped>
.el-form {
  // /deep/ .el-form-item__label {
  //   padding: 0;
  // }
  // /deep/ .el-textarea__inner {
  //   height: 88px;
  // }
  //ItemuploadFile是动态类名，只有上传部分才会出现
  .ItemuploadFile /deep/ .el-form-item__content {
    line-height: normal;
  }
  .Itempicture_url /deep/ .el-form-item__content {
    font-size: 12px;
    line-height: 12px;
    color: #606266;
  }
  /deep/ .Itemopening_hours,
  /deep/ .Itemclosing_time {
    .el-input__inner {
      height: 32px !important;
      line-height: 32px !important;
      border: 1px solid #d9d9d9 !important;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 162px !important;
    }
  }
  .el-col-24:nth-of-type(6) {
    display: none;
  }
  /deep/.el-input__prefix {
    display: none !important;
  }

  /deep/ .el-form-item__label {
    text-align: right !important;
  }
  /deep/ .el-col-24:nth-of-type(2) .el-form-item__error {
    top: 90%;
  }
  .el-col-24:nth-of-type(5) {
    width: 105% !important;
  }
  /deep/.el-textarea .el-input__count {
    bottom: 9px !important;
    line-height: 12px !important;
  }
}
</style>
