import OSS from 'ali-oss'
import Vue from 'vue'

export async function uploadFiles(
  obj: any,
  name: string,
  callback: any,
  errCallback?: any
) {
  const blob = new Blob([JSON.stringify(obj)], { type: '' })
  const time = new Date()
  const path = '/bluenMaterialData/' + time.getTime() + '_' + name + '.json'
  const file = new File([blob], path, {
    type: '',
    lastModified: Date.now(),
  })
  await Vue.prototype.$getByToken('/get_oss_token', {}, (res: any) => {
    if (res.status == 1) {
      const client = new OSS({
        region: 'oss-cn-hangzhou',
        accessKeyId: res.data.AccessKeyId,
        accessKeySecret: res.data.AccessKeySecret,
        stsToken: res.data.SecurityToken,
        bucket: 'eggrj',
      })
      client
        .multipartUpload(path, file, {})
        .then(() => {
          const url = 'https://eggrj.oss-cn-hangzhou.aliyuncs.com' + path
          callback(url)
        })
        .catch((err) => {
          console.error(err)
          errCallback && errCallback()
          Vue.prototype.$message({
            type: 'error',
            message: '<span id="material_upload_error">上传失败!</span>',
            duration: 1500,
            offset: 60,
            dangerouslyUseHTMLString: true,
          })
        })
    }
  })
}
