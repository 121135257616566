var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('a-modal',{attrs:{"wrapClassName":"try-production-dialog","title":_vm.$t('common.tip'),"centered":"","on-ok":"handleOk"},on:{"cancel":_setup.handleCancel},model:{value:(_setup.state.isShowProductionDialog),callback:function ($$v) {_vm.$set(_setup.state, "isShowProductionDialog", $$v)},expression:"state.isShowProductionDialog"}},[_c('template',{slot:"footer"},[_c('div',{staticClass:"footer flex flex-main--justify pl10 pr10"},[_c('a-button',{attrs:{"type":"primary","id":"try_production_sample_btn"},on:{"click":function($event){return _setup.dealLayoutDataToMaterial(1)}}},[_vm._v(" "+_vm._s(_vm.$t('arrangedPage.trialProduction.trialProductionSample'))+" ")]),_c('a-button',{attrs:{"id":"try_production_confirm_btn"},on:{"click":function($event){;(_setup.state.isShowDisclaimerAgreement = true),
              (_setup.state.isShowProductionDialog = false)}}},[_vm._v(" "+_vm._s(_vm.$t('arrangedPage.trialProduction.trialProduced'))+" ")])],1)]),_c('p',{staticStyle:{"display":"flex","align-items":"baseline"}},[_c('i',{staticClass:"iconfont icon-warn"}),_c('span',[_vm._v(_vm._s(_setup.state.tipText))])])],2),_c('a-modal',{attrs:{"wrapClassName":"try-production-dialog","width":"600px","centered":"","on-ok":"handleOk"},on:{"cancel":_setup.handleCancel},model:{value:(_setup.state.isShowDisclaimerAgreement),callback:function ($$v) {_vm.$set(_setup.state, "isShowDisclaimerAgreement", $$v)},expression:"state.isShowDisclaimerAgreement"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"center title bold fs20"},[_vm._v(" "+_vm._s(_vm.$t('common.freeAgreement'))+" ")])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"footer flex flex-main--justify pl10 pr10"},[_c('a-button',{attrs:{"id":"try_production_previous_btn"},on:{"click":function($event){;(_setup.state.isShowProductionDialog = true),
              (_setup.state.isShowDisclaimerAgreement = false)}}},[_vm._v(" "+_vm._s(_vm.$t('common.preStep'))+" ")]),_c('a-button',{attrs:{"type":"primary","id":"try_production_agree_btn","disabled":!_setup.state.fileList.length ||
            !_setup.state.inputPhoneNumber ||
            !_setup.state.inputPassword ||
            _setup.state.isUpload},on:{"click":_setup.handleConfirm}},[_vm._v(" "+_vm._s(_setup.state.isUpload ? _vm.$t('common.uploading') : _vm.$t('common.iAgree'))+" "),_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_setup.state.isUpload),expression:"state.isUpload"}],attrs:{"type":"loading"}})],1)],1)]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('arrangedPage.trialProduction.trialWarning'))+" ")]),_c('br'),_c('p',[_vm._v(" "+_vm._s(_vm.$t('arrangedPage.trialProduction.pleaseUploadTrial'))+" ")]),_c('a-upload',{attrs:{"accept":".jpg,.jpeg,.png,.bmp","list-type":"picture-card","beforeUpload":() => false,"file-list":_setup.state.fileList},on:{"preview":_setup.handlePreview,"change":_setup.handleChange}},[(_setup.state.fileList.length < 4)?_c('div',{attrs:{"id":"try_production_upload_sample_btn"}},[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t('arrangedPage.trialProduction.uploadTrial'))+" ")])],1):_vm._e()]),_c('div',[_c('div',{staticClass:"flex flex-main--justify mb10"},[_c('span',[_vm._v("手机号：")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_setup.state.errInfo.phone),expression:"state.errInfo.phone"}],staticClass:"error-pass"},[_vm._v("请输入正确手机号")])]),_c('div',{staticClass:"flex flex-main--justify"},[_c('a-select',{staticClass:"areaSelect mr8",attrs:{"show-search":"","showArrow":"","filter-option":(input, option) =>
              option.componentOptions.children[0].text
                .toLowerCase()
                .trim()
                .includes(input.toLowerCase()),"id":"try_production_area_code_select"},model:{value:(_setup.state.areaCode),callback:function ($$v) {_vm.$set(_setup.state, "areaCode", $$v)},expression:"state.areaCode"}},_vm._l((_setup.areaCodeOptions),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.area_code}},[_vm._v(_vm._s(item.country))])}),1),_c('a-input',{attrs:{"placeholder":"请输入您的手机号","autoComplete":"off","id":"try_production_phone_number_input","maxLength":11},on:{"change":function($event){_setup.state.inputPhoneNumber = _setup.state.inputPhoneNumber.replace(
              /[^\d]/g,
              ''
            )
            _setup.state.errInfo.phone = false}},model:{value:(_setup.state.inputPhoneNumber),callback:function ($$v) {_vm.$set(_setup.state, "inputPhoneNumber", $$v)},expression:"state.inputPhoneNumber"}})],1)]),_c('div',{staticClass:"flex flex-main--justify mt10 mb10"},[_c('span',[_vm._v("验证码：")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_setup.state.errInfo.password),expression:"state.errInfo.password"}],staticClass:"error-pass"},[_vm._v("验证码错误")])]),_c('div',{staticClass:"flex flex-main--justify"},[_c('a-input',{staticClass:"send-msg mr10 grow",attrs:{"placeholder":"请输入验证码","id":"try_production_password_input"},on:{"change":function($event){_setup.state.errInfo.password = false}},model:{value:(_setup.state.inputPassword),callback:function ($$v) {_vm.$set(_setup.state, "inputPassword", $$v)},expression:"state.inputPassword"}}),(_setup.state.isShowCountDown)?_c('a-button',{attrs:{"disabled":"","id":"try_production_countDown_btn"}},[_vm._v("获取验证码("+_vm._s(_setup.state.countdown)+")")]):_c('a-button',{attrs:{"disabled":!_setup.state.fileList.length,"type":"primary","id":"try_production_send_captcha_btn"},on:{"click":_setup.handleSendCaptcha}},[_vm._v("获取验证码")])],1),_c('span',{staticClass:"mt20 block",staticStyle:{"color":"#c0c0c0"}},[_vm._v("为了您的账号数据安全，需验证当前操作人员信息")])],2),_c('a-modal',{attrs:{"visible":_setup.state.previewVisible,"footer":null,"centered":""},on:{"cancel":() => (_setup.state.previewVisible = false)}},[_c('img',{staticClass:"w100",attrs:{"alt":"example","src":_setup.state.previewImageSrc}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }