<template>
  <div
    class="not-paiban-detail t0 l0 w100 h100"
    id="material_not_paiban_detail_modal"
    v-if="showPartDetail"
  >
    <div class="content relative r0 l0 bg-white br4 pb15">
      <div class="head flex flex-main--justify pb10">
        <div>
          <i
            class="iconfont icon-left mr5 cursor-pointer"
            style="color: #18a8c7; font-size: 12px"
            @click="handleClosePartDetail"
            id="not_paiban_detail_back"
          ></i>
          <span>
            <!-- 未排小板清单 -->
            {{ $t('materialPage.paibanSetting.customPlankOrderDetail') }}
          </span>
        </div>
        <i
          class="iconfont icon-close cursor-pointer"
          style="font-size: 12px"
          @click="handleClosePartDetail"
          id="not_paiban_detail_close"
        ></i>
      </div>
      <a-tabs
        v-model="activePartTab"
        @tabClick="handleTabClick"
        class="part-detail-tabs"
        id="not_paiban_detail_tabs"
      >
        <a-tab-pane
          key="全部"
          :tab="$t('common.all')"
          id="not_paiban_detail_all_tab"
        ></a-tab-pane>
        <a-tab-pane
          key="剩余小板清单"
          :tab="$t('materialPage.paibanSetting.surplusPlank')"
          id="not_paiban_detail_surplus_tab"
        ></a-tab-pane>
        <a-tab-pane
          key="超尺板件清单"
          :tab="$t('materialPage.paibanSetting.overSizePlank')"
          id="not_paiban_detail_overSize_tab"
        ></a-tab-pane>
      </a-tabs>
      <el-table
        max-height="560"
        :header-cell-style="{
          backgroundColor: '#F3F3F3',
          height: '36px',
          padding: 0,
          color: 'rgba(0,0,0,0.6)',
          fontWeight: 400,
        }"
        :header-row-style="{
          lineHeight: '36px',
        }"
        :cell-style="{
          color: 'rgba(0,0,0,0.8)',
          padding: 0,
        }"
        :row-style="{ height: '40px' }"
        :data="allList"
        class="not-paiban-detail-table"
      >
        <el-table-column
          v-for="item in partColumns"
          :key="item.prop"
          :prop="item.prop"
          :label="
            ['blankHeight', 'blankWidth', 'thick'].includes(item.prop)
              ? $t(`common.${item.transKey}`) + 'mm'
              : $t(`common.${item.transKey}`)
          "
          :width="item.width"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            <span>{{ row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPartDetail: {
      type: Boolean,
      default: false,
    },
    paibanMessage: {
      type: Object,
      default: () => ({}),
    },
    filterPlankList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activePartTab: '全部',
      allList: [],
      surplusPartList: [],
      oversizePartList: [],
      partColumns: [
        { label: '订单号', prop: 'orderNo', width: '180', transKey: 'orderNo' },
        {
          label: '项目地址',
          prop: 'address',
          width: '180',
          transKey: 'orderAddress',
        },
        {
          label: '房间名',
          prop: 'roomName',
          width: '180',
          transKey: 'roomName',
        },
        {
          label: '板件名称',
          prop: 'partName',
          width: '180',
          transKey: 'plankName',
        },
        {
          label: '板件材质',
          prop: 'matCode',
          width: '180',
          transKey: 'plankMatCode',
        },
        {
          label: '板件颜色',
          prop: 'texture',
          width: '180',
          transKey: 'plankColor',
        },
        {
          label: '下料长度mm',
          prop: 'blankHeight',
          width: '120',
          transKey: 'cutHeight',
        },
        {
          label: '下料宽度mm',
          prop: 'blankWidth',
          width: '120',
          transKey: 'cutWidth',
        },
        { label: '厚度mm', prop: 'thick', width: '180', transKey: 'thick' },
        { label: '纹理', prop: 'texDir', width: '100', transKey: 'texture' },
        {
          label: '标签条码',
          prop: 'plankNum',
          width: '130',
          transKey: 'plankNum',
        },
        { label: '板号', prop: 'plankID', width: '100', transKey: 'plankNo' },
        { label: '数量', prop: 'amount', width: '100', transKey: 'count' },
      ],
    }
  },
  methods: {
    // 关闭弹窗
    handleClosePartDetail() {
      this.$emit('onClosePartDetail')
    },
    // tab点击事件
    handleTabClick(tab) {
      if (tab == '全部') {
        this.allList = [...this.surplusPartList, ...this.oversizePartList]
      } else if (tab == '剩余小板清单') {
        this.allList = this.surplusPartList
      } else {
        this.allList = this.oversizePartList
      }
    },
  },
  watch: {
    paibanMessage: {
      handler(newV) {
        const partList = this.$props.filterPlankList
          // ?.map((item) => item.parts)
          // .flat(1)
          // .filter((item) => item.isSelected)
        this.allList = []
        this.surplusPartList = []
        this.oversizePartList = []
        this.activePartTab = '全部'
        if (Object.keys(newV).length) {
          Object.keys(newV).forEach((key) => {
            newV[key].message.forEach((message) => {
              if (message.message == '大板数量不够') {
                partList.forEach((part) => {
                  message.leave_planks.forEach((item) => {
                    if (part.partUniqueId == item) {
                      if (
                        !this.surplusPartList
                          .map((partItem) => partItem.partUniqueId)
                          .includes(part.partUniqueId)
                      ) {
                        this.surplusPartList.push({
                          ...part,
                          texDir: this.$t(`common.${part.texDir}`),
                          amount: message.leave_planks.filter(
                            (item) => item == part.partUniqueId
                          ).length,
                        })
                      }
                    }
                  })
                })
              } else if (message.message.includes('超尺')) {
                partList.forEach((part) => {
                  message.leave_planks.forEach((item) => {
                    if (part.partUniqueId == item) {
                      if (
                        !this.oversizePartList
                          .map((partItem) => partItem.partUniqueId)
                          .includes(part.partUniqueId)
                      ) {
                        this.oversizePartList.push({
                          ...part,
                          texDir: this.$t(`common.${part.texDir}`),
                          amount: message.leave_planks.filter(
                            (item) => item == part.partUniqueId
                          ).length,
                        })
                      }
                    }
                  })
                })
              }
            })
          })
          this.allList = [...this.surplusPartList, ...this.oversizePartList]
        }
      },
    },
    showPartDetail: {
      handler(newV) {
        if (newV) {
          this.$nextTick(() => {
            // 给element表格表头添加title
            const theadCell = document.querySelectorAll(
              '.not-paiban-detail-table .el-table__header-wrapper .cell'
            )
            theadCell.forEach((item, index) => {
              item.setAttribute(
                'title',
                this.$t(`common.${this.partColumns[index].transKey}`)
              )
            })
          })
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.not-paiban-detail {
  position: fixed;
  z-index: 1051;
  margin: auto;
  background: rgba(0, 0, 0, 0.7);
  .content {
    top: 50%;
    box-sizing: border-box;
    width: 1000px;
    height: 742px;
    margin: 0 auto;
    padding: 24px;
    transform: translateY(-50%);
    .part-detail-tabs {
      /deep/.ant-tabs-tab,
      .ant-tabs-tab-active .ant-tabs-tab {
        margin-right: 20px;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
      }
    }
  }
  /deep/&-table {
    .el-table__header-wrapper .cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
