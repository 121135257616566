<template>
  <div class="msg-dialog t0 l0 h100 w100">
    <div class="dialog-box t0 b0 r0 l0 bg-white br2">
      <div class="title mb12">
        <span
          class="iconfont icon-warn fs16"
          style="color: rgba(255, 173, 20, 1)"
        ></span>

        <span class="warn-title">{{ '温馨提示' }}</span>
      </div>
      <div class="msg-main fs14">
        <div>
          <span
            v-for="(objValue, key) of necessaryTableIncorrectFormat"
            :key="Number(key)"
            >检测到第{{ Number(key) + 1 }}行，板件"{{
              objValue.join('、')
            }}"数据格式错误<br
          /></span>
        </div>
      </div>
      <div class="msg-bottom flex flex-main--right r10 b10">
        <div class="msg-bottom-btn">
          <a-button class="cancel-btn mr10 br2" @click="cancelBtn"
            >取消</a-button
          >
          <a-button class="confirm-btn color-f br2" @click="confirmBtn"
            >确定</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    necessaryTableIncorrectFormat: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {}
  },
  methods: {
    confirmBtn() {
      this.$emit('confirm')
    },
    cancelBtn() {
      this.$emit('cancel')
    },
  },
}
</script>

<style scoped>
.msg-dialog {
  position: fixed;

  z-index: 1000;
  background: #0008;
}
.dialog-box {
  position: fixed;
  width: 500px;
  height: 220px;
  margin: auto;
  padding: 33px 32px 24px 33px;
}

.dialog-box .title .warn-title {
  color: rgba(0, 0, 0, 0.85);
}

.msg-main {
  height: 100px;
  margin-bottom: 20px;
  margin-left: 39px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.65);
}

.msg-bottom .cancel-btn {
  height: 32px;
}
.msg-bottom .confirm-btn {
  height: 32px;
  background: rgba(24, 168, 199, 1);
}
.msg-bottom {
  position: absolute;
}
</style>
