import { httpRequest } from '@/apis/request'

enum Apis {
  analyzeBujian = 'workshop/supplements/layout',
  batchBujian = 'workshop/supplements',
}

interface BatchBujianType {
  plate_info: {
    plKId: number
    matCode: string
    color: string
    size: string
    from: string
  }
  room_id: number | string
  ggid: string
  tag_barcode: number | string
}

interface AddBujianParams {
  supplements_info: BatchBujianType[]
  reason: string
  duty: string
}

export function analyzeLayout(parts: any[], isHardware = false) {
  return httpRequest.post(Apis.analyzeBujian, {
    supplements: parts,
    generate_ironware: isHardware,
  })
}

export function batchBujian(params: AddBujianParams) {
  return httpRequest.post(Apis.batchBujian, params)
}
