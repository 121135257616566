<template>
  <div class="holes_slots_wapper">
    <div class="holes_slots_popUp">
      <div class="HS_popUp_head">
        <span>{{ plank.plkName }}</span>
        <span @click="hidePopUp" class="cursor"
          ><i class="iconfont icon-close" id="material_close_slot_hole_btn"></i
        ></span>
      </div>
      <div class="HS_wapper">
        <div class="drawPlank_box">
          <div
            :class="['change_f_b', 'changeBox', hasBackHS ? '' : 'onlyOneFace']"
          >
            <span
              @click="changeFB('front')"
              :class="nowPlankDir == 'front' ? 'active' : ''"
              id="material_front_btn"
              >正面</span
            >
            <span
              v-if="hasBackHS"
              @click="changeFB('back')"
              :class="nowPlankDir == 'back' ? 'active' : ''"
              id="material_opposite_btn"
              >反面</span
            >
          </div>
          <canvas id="plankCanvas"></canvas>
        </div>
        <div class="HS_infoList">
          <div class="change_h_s changeBox">
            <span
              @click="changeHS('slot')"
              :class="nowHSSHow == 'slot' ? 'active' : ''"
              id="material_slot_btn"
              >槽</span
            >
            <span
              @click="changeHS('hole')"
              :class="nowHSSHow == 'hole' ? 'active' : ''"
              id="material_hole_btn"
              >孔</span
            >
          </div>
          <div class="HS_table" v-show="nowHSSHow == 'hole'">
            <div class="HS_table_head">
              <span
                :style="{ width: `${item.width}px` }"
                v-for="(item, index) in holesTableCol"
                :key="index"
                >{{ item.value }}</span
              >
            </div>
            <div class="HS_table_body">
              <div
                @click="changeTableItem(hole, 'hole')"
                :class="['HS_table_item', recordIndex == holeI ? 'active' : '']"
                v-for="(hole, holeI) in holes"
                :key="holeI"
              >
                <span
                  :style="{ width: `${item.width}px` }"
                  v-for="(item, index) in holesTableCol"
                  :key="index"
                  >{{ cacleHSValue(item.props, holeI, hole, 'holes') }}</span
                >
              </div>
            </div>
          </div>
          <div class="HS_table" v-show="nowHSSHow == 'slot'">
            <div class="HS_table_head">
              <span
                :style="{ width: `${item.width}px` }"
                v-for="(item, index) in slotsTableCol"
                :key="index"
                >{{ item.value }}</span
              >
            </div>
            <div class="HS_table_body">
              <div
                @click="changeTableItem(slot, 'slot')"
                :class="['HS_table_item', recordIndex == slotI ? 'active' : '']"
                v-for="(slot, slotI) in [
                  ...slots,
                  ...curveHoles.filter((c) => c.disType == 'CCCurveSlot'),
                ]"
                :key="slotI"
              >
                <span
                  :style="{ width: `${item.width}px` }"
                  v-for="(item, index) in slotsTableCol"
                  :key="index"
                  >{{ cacleHSValue(item.props, slotI, slot, 'slots') }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 正反面
      nowPlankDir: 'front',
      nowHSSHow: 'slot',
      hasBackHS: false,
      holesTableCol: ['名称', '直径(mm)', '深度(mm)'],
      holesTableCol: [
        {
          value: '名称',
          width: 88,
          props: 'name',
        },
        {
          value: '直径(mm)',
          width: 166,
          props: 'diameter',
        },
        {
          value: '深度(mm)',
          width: 166,
          props: 'deep',
        },
      ],
      slotsTableCol: [
        {
          value: '名称',
          width: 88,
          props: 'name',
        },
        {
          value: '长度(mm)',
          width: 110,
          props: 'height',
        },
        {
          value: '宽度(mm)',
          width: 110,
          props: 'width',
        },
        {
          value: '深度(mm)',
          width: 110,
          props: 'deep',
        },
      ],
      holes: [],
      slots: [],
      curveHoles: [],
      mycanvas: null,
      scale: 1,
      // 板件居中偏移量
      offsetW: 0,
      offsetH: 0,
      plankWidth: 0,
      plankHeight: 0,
      recordIndex: -1,
    }
  },
  computed: {
    cacleHSValue() {
      return (val, index, data, type) => {
        let req = ''
        switch (val) {
          case 'name':
            req = data.name ?? `异形槽${index + 1}`
            break
          case 'diameter':
            req = Number(data.diameter).toFixed(2)
            break
          case 'deep':
            req = Number(data.deep).toFixed(2)
            break
          case 'width':
            if (!data.pt1) {
              req = (
                Math.max(...data.path.map((p) => p.x)) -
                Math.min(...data.path.map((p) => p.x))
              ).toFixed(2)
            } else {
              let pt1 = data.pt1
              let pt2 = data.pt2
              // 横向
              if (Math.abs(pt1.x - pt2.x) < 0.0001) {
                req = Number(data.width).toFixed(2)
              } else if (Math.abs(pt1.y - pt2.y) < 0.0001) {
                // 竖向
                req = Math.abs(pt2.x - pt1.x).toFixed(2)
              }
            }

            break
          case 'height':
            if (!data.pt1) {
              req = (
                Math.max(...data.path.map((p) => p.y)) -
                Math.min(...data.path.map((p) => p.y))
              ).toFixed(2)
            } else {
              let pt1c = data.pt1
              let pt2c = data.pt2
              if (Math.abs(pt1c.x - pt2c.x) < 0.0001) {
                req = Math.abs(pt2c.y - pt1c.y).toFixed(2)
              } else if (Math.abs(pt1c.y - pt2c.y) < 0.0001) {
                req = Number(data.width).toFixed(2)
              }
            }

            break
          default:
            req = ''
            break
        }

        return req
      }
    },
  },
  props: {
    plank: {
      default: null,
      type: Object,
    },
  },
  methods: {
    hidePopUp() {
      this.$emit('hide')
    },
    changeFB(val) {
      if (val == this.nowPlankDir) return
      this.recordIndex = -1
      this.nowPlankDir = val
      this.draw()
      this.clearColor()
    },
    changeHS(val) {
      if (val == this.nowHSSHow) return
      this.recordIndex = -1
      this.nowHSSHow = val
      this.clearColor()
    },
    clearColor() {
      let KlcassS = this.mycanvas.getObjects()
      KlcassS.forEach((item) => {
        if (item.typeName == 'hole' || item.typeName == 'slot') {
          item.set({
            stroke: '#9C7231',
            strokeWidth: 0.5,
          })
        }
      })
      this.mycanvas.renderAll()
    },
    changeTableItem(data, type) {
      if (data.type == 'hole' || data.type == 'slot') {
        if (data.side == -1) {
          this.changeFB('back')
        } else if (data.side == 1) {
          this.changeFB('front')
        }
      }
      let KlcassS = this.mycanvas.getObjects()
      KlcassS.forEach((item) => {
        if (item.typeName == 'hole' || item.typeName == 'slot') {
          item.set({
            stroke: '#9C7231',
            strokeWidth: 0.5,
          })
        }
        if (
          item.hasOwnProperty('recordIndex') &&
          item.typeName == type &&
          Number(item.recordIndex) == Number(data.recordIndex)
        ) {
          this.recordIndex = data.recordIndex
          item.set({
            stroke: '#18a8c7',
            strokeWidth: 1,
          })
        }
      })
      this.mycanvas.renderAll()
    },
    drawHoles(holes) {
      if (this.nowPlankDir == 'front') {
        holes = holes.filter((item) => Number(item.side) == 1)
      } else {
        holes = holes.filter((item) => Number(item.side) == -1)
      }
      for (let i = 0; i < holes.length; i++) {
        let hole = holes[i]
        let holeCircle = new fabric.Circle({
          top: (hole.center.y - hole.diameter / 2) * this.scale + this.offsetH,
          left: (hole.center.x - hole.diameter / 2) * this.scale + this.offsetW,
          fill: 'transparent',
          stroke: '#9C7231',
          strokeWidth: 0.5,
          radius: (hole.diameter / 2) * this.scale,
          typeName: 'hole',
          selectable: false,
          hasControls: false,
          recordIndex: hole.recordIndex,
        })
        this.mycanvas.add(holeCircle)
      }
    },
    drawSlots(slots) {
      if (this.nowPlankDir == 'front') {
        slots = slots.filter((item) => Number(item.side) == 1)
      } else {
        slots = slots.filter((item) => Number(item.side) == -1)
      }
      for (let i = 0; i < slots.length; i++) {
        let slot = slots[i]
        let startPoint = null
        let slotWidth
        let slotHeight
        let length
        // 横向拉槽
        if (slot.pt1.x == slot.pt2.x) {
          length = Math.abs(slot.pt1.y - slot.pt2.y)
          slotWidth = slot.width * this.scale
          slotHeight = length * this.scale
          if (slot.pt1.y < slot.pt2.y) {
            startPoint = { left: slot.pt1.x - slot.width / 2, top: slot.pt1.y }
          } else {
            startPoint = { left: slot.pt2.x - slot.width / 2, top: slot.pt2.y }
          }
        }
        if (slot.pt1.y == slot.pt2.y) {
          length = Math.abs(slot.pt1.x - slot.pt2.x)
          slotWidth = length * this.scale
          slotHeight = slot.width * this.scale
          if (slot.pt1.x < slot.pt2.x) {
            startPoint = { left: slot.pt1.x, top: slot.pt1.y - slot.width / 2 }
          } else {
            startPoint = { left: slot.pt2.x, top: slot.pt2.y - slot.width / 2 }
          }
        }
        let slotRect = new fabric.Rect({
          top: startPoint.top * this.scale + this.offsetH,
          left: startPoint.left * this.scale + this.offsetW,
          width: slotWidth,
          height: slotHeight,
          fill: '#eeeea1',
          stroke: '#9C7231',
          strokeWidth: 0.5,
          typeName: 'slot',
          selectable: false,
          hasControls: false,
          recordIndex: slot.recordIndex,
        })
        this.mycanvas.add(slotRect)
      }
    },
    drawCurveHoles() {
      const curveHoles = this.plank.curveHoles
        .filter((c) => c.disType == 'CCCurveSlot')
        .filter((hole) =>
          this.nowPlankDir == 'front' ? hole.side == 1 : hole.side != 1
        )
      if (curveHoles) {
        curveHoles.forEach((hole) => {
          let path = (hole.shape ?? hole.path).map((point) => ({
            x: this.offsetW + point.x * this.scale,
            y: this.offsetH + point.y * this.scale,
          }))
          const poly = new fabric.Polygon(path, {
            fill: '#eeeea1',
            stroke: '#9C7231',
            selectable: false,
            hasControls: false,
            typeName: 'slot',
          })
          this.mycanvas.add(poly)
        })
      }
    },
    drawPlank() {
      let scale = this.scale
      let path = this.plank.path
      if (path) {
        path = JSON.parse(JSON.stringify(this.plank.path))
        // 异形
        for (let i = 0; i < path.length; ++i) {
          let minX = []
          let minY = []
          for (let k = 0; k < path[i].length; ++k) {
            path[i][k].x = path[i][k].x * scale
            path[i][k].y = path[i][k].y * scale
            if (i > 0) {
              minX.push(path[i][k].x)
              minY.push(path[i][k].y)
            }
          }
          let poly
          if (i == 0) {
            poly = new fabric.Polygon(path[0], {
              top: this.offsetH,
              left: this.offsetW,
              fill: '#ECCEA0',
              stroke: '#0008',
              selectable: false,
              hasControls: false,
              typeName: 'plank',
            })
          } else {
            let top = 0
            let left = 0
            if (minX[0]) {
              top = Math.min(...minY)
              left = Math.min(...minX)
            }
            poly = new fabric.Polygon(path[i], {
              top: this.offsetH + top,
              left: this.offsetW + left,
              fill: '#fff',
              stroke: '#0008',
              selectable: false,
              hasControls: false,
              typeName: 'plank',
            })
          }

          this.mycanvas.add(poly)
        }
      } else {
        let pl = new fabric.Rect({
          width: this.plankWidth * scale,
          height: this.plankHeight * scale,
          fill: '#ECCEA0',
          top: this.offsetH,
          left: this.offsetW,
          typeName: 'plank',
          selectable: false,
          hasControls: false,
          stroke: '#0008',
        })
        this.mycanvas.add(pl)
      }
      // 绘制长宽尺寸
      let hSizePath = [
        { x: 10, y: this.offsetH },
        { x: 20, y: this.offsetH },
        { x: 15, y: this.offsetH },
        { x: 15, y: this.offsetH + this.plankHeight * scale },
        { x: 20, y: this.offsetH + this.plankHeight * scale },
        { x: 10, y: this.offsetH + this.plankHeight * scale },
        { x: 15, y: this.offsetH + this.plankHeight * scale },
        { x: 15, y: this.offsetH },
      ]
      let vSizePath = [
        { x: this.offsetW, y: 10 },
        { x: this.offsetW, y: 20 },
        { x: this.offsetW, y: 15 },
        { x: this.offsetW + this.plankWidth * scale, y: 15 },
        { x: this.offsetW + this.plankWidth * scale, y: 20 },
        { x: this.offsetW + this.plankWidth * scale, y: 10 },
        { x: this.offsetW + this.plankWidth * scale, y: 15 },
        { x: this.offsetW, y: 15 },
      ]
      let hSize = new fabric.Polygon(hSizePath, {
        top: this.offsetH,
        left: this.offsetW + 10 + this.plankWidth * scale,
        stroke: '#ccc',
        selectable: false,
        hasControls: false,
      })
      let vSize = new fabric.Polygon(vSizePath, {
        top: this.offsetH + 10 + this.plankHeight * scale,
        left: this.offsetW,
        stroke: '#ccc',
        selectable: false,
        hasControls: false,
      })
      let heightSize = this.calcTextSize(this.plankHeight)
      let widthSize = this.calcTextSize(this.plankWidth)
      // 绘制长宽数值
      let WText = new fabric.Text(String(this.plankWidth), {
        left:
          this.offsetW + (this.plankWidth * scale) / 2 - widthSize.width / 2,
        top: this.offsetH + this.plankHeight * scale + 20,
        fontSize: 14,
        selectable: false,
        hasControls: false,
        fill: '#666',
      })
      let HText = new fabric.Text(String(this.plankHeight), {
        left: this.offsetW + this.plankWidth * scale + 20,
        top:
          this.offsetH + (this.plankHeight * scale) / 2 - heightSize.height / 2,
        fontSize: 14,
        fill: '#666',
        selectable: false,
        hasControls: false,
      })
      this.mycanvas.add(vSize)
      this.mycanvas.add(hSize)
      this.mycanvas.add(WText)
      this.mycanvas.add(HText)
    },
    checkCurrenHS(Klass) {
      let typeName = Klass.typeName
      let KlassS = this.mycanvas
        .getObjects()
        .filter((item) => item.typeName == 'hole' || item.typeName == 'slot')
      if (typeName == 'hole' || typeName == 'slot') {
        this.checkCurrenHS(KlassS, Klass)
        // 清除所有孔槽选中状态
        KlassS.forEach((item) => {
          item.set({
            stroke: '#9C7231',
            strokeWidth: 0.5,
          })
        })
        // 选中孔槽
        Klass.set({
          stroke: '#18a8c7',
          strokeWidth: 1,
        })
      }
    },
    calcTextSize(text) {
      let span = document.createElement('span')
      let result = {}
      result.width = span.offsetWidth
      result.height = span.offsetHeight
      span.style.visibility = 'hidden'
      span.style.fontSize = '14px'
      span.style.fontFamily = 'PingFangSC-Regular, PingFang SC'
      span.style.display = 'inline-block'
      document.body.appendChild(span)
      if (typeof span.textContent != 'undefined') {
        span.textContent = text
      } else {
        span.innerText = text
      }
      result.width =
        parseFloat(window.getComputedStyle(span).width) - result.width
      result.height =
        parseFloat(window.getComputedStyle(span).height) - result.height
      document.body.removeChild(span)
      return result
    },
    draw() {
      // plankCanvas
      let scale = this.scale
      let plankWidth
      let plankHeight
      let plank = this.plank
      if (this.plank.texDir == '横纹') {
        plankWidth = plank.specHeight
        plankHeight = plank.specWidth
      } else {
        plankWidth = plank.specWidth
        plankHeight = plank.specHeight
      }
      this.plankWidth = plankWidth
      this.plankHeight = plankHeight
      let offsetW = 150 - (plankWidth * scale) / 2
      let offsetH = 150 - (plankHeight * scale) / 2
      this.offsetW = offsetW
      this.offsetH = offsetH
      let holes = this.holes.filter((item) => item.type == 'hole')
      let slots = this.slots.filter((item) => item.type == 'slot')
      let curveHoles = this.plank.curveHoles
      if (this.mycanvas) {
        this.mycanvas.clear()
        this.mycanvas.remove(this.mycanvas.getActiveObject())
        this.drawPlank()

        if (curveHoles.length) {
          this.drawCurveHoles()
        }
        if (holes.length) {
          this.drawHoles(holes)
        }
        if (slots.length) {
          this.drawSlots(slots)
        }
      } else {
        let canvas = new fabric.Canvas('plankCanvas', {
          width: 300,
          height: 300,
          Selection: false,
        })
        this.mycanvas = canvas
        this.drawPlank()
        if (curveHoles.length) {
          this.drawCurveHoles()
        }
        if (holes.length) {
          this.drawHoles(holes)
        }
        if (slots.length) {
          this.drawSlots(slots)
        }
        canvas.on({
          'mouse:down': (e) => {
            if (e.target) {
              let Klass = e.target
              let typeName = Klass.typeName
              this.checkCurrenHS(Klass)
              if (typeName == 'hole' || typeName == 'slot') {
                this.nowHSSHow = typeName
                if (Klass.hasOwnProperty('recordIndex')) {
                  this.recordIndex = Number(Klass.recordIndex)
                } else {
                  this.recordIndex = -1
                }
              } else if (typeName == 'plank') {
                this.isMove = true
                this.mycanvas.discardActiveObject()
                var sel = new fabric.ActiveSelection(
                  this.mycanvas.getObjects(),
                  {
                    canvas: this.mycanvas,
                  }
                )
                this.mycanvas.setActiveObject(sel)
                sel.set({
                  hasControls: false,
                })
                this.mycanvas.requestRenderAll()
              }
            }
            this.mycanvas.renderAll()
          },
          'mouse:wheel': (e) => {
            var zoom = (event.deltaY > 0 ? -0.1 : 0.1) + this.mycanvas.getZoom()
            zoom = Math.max(0.1, zoom) //最小为原来的1/10
            zoom = Math.min(3, zoom) //最大是原来的3倍
            var zoomPoint = new fabric.Point(e.pointer.x, e.pointer.y)
            this.mycanvas.zoomToPoint(zoomPoint, zoom)
          },
        })
      }
    },
  },
  mounted() {
    let body = document.body
    body.style.overflow = 'hidden'
    this.scale = Math.min.apply(null, [260 / 2440, 260 / 1220])
    //
    // 处理孔槽数据
    let plank = this.plank
    // 获取正面孔槽和反面孔槽
    let f_holes = Array.isArray(plank.holes)
      ? plank.holes.filter((item) => item.side == 1)
      : []
    let b_holes = Array.isArray(plank.holes)
      ? plank.holes.filter((item) => item.side == -1)
      : []
    let f_slots = Array.isArray(plank.slots)
      ? plank.slots.filter((item) => item.side == 1)
      : []
    let b_slots = Array.isArray(plank.slots)
      ? plank.slots.filter((item) => item.side == -1)
      : []
    f_holes.forEach((item, index) => {
      item.name = `正面孔${index + 1}`
    })
    b_holes.forEach((item, index) => {
      item.name = `反面孔${index + 1}`
    })
    f_slots.forEach((item, index) => {
      item.name = `正面槽${index + 1}`
    })
    b_slots.forEach((item, index) => {
      item.name = `反面槽${index + 1}`
    })
    let holes = f_holes.concat(b_holes)
    let slots = f_slots.concat(b_slots)
    holes = holes.map((item, index) => {
      return {
        ...item,
        recordIndex: index,
      }
    })
    slots = slots.map((item, index) => {
      return {
        ...item,
        recordIndex: index,
      }
    })
    let sholes = Array.isArray(plank.sholes) ? plank.sholes : []
    let sslots = Array.isArray(plank.sslots) ? plank.sslots : []
    sholes.forEach((item, index) => {
      item.name = `侧孔${index + 1}`
    })
    sslots.forEach((item, index) => {
      item.name = `侧槽${index + 1}`
    })
    if (Array.isArray(holes) && Array.isArray(sholes)) {
      this.holes = holes.concat(sholes)
    } else {
      this.holes = []
    }
    if (Array.isArray(slots) && Array.isArray(sslots)) {
      this.slots = slots.concat(sslots)
    } else {
      this.slots = []
    }
    if (plank.curveHoles) {
      this.curveHoles = plank.curveHoles
    }
    // 判断是否有正反面孔槽、显示是否可以切换正反面
    if (
      (holes.filter((item) => item.side == 1) &&
        holes.filter((item) => item.side == -1).length) ||
      (slots.filter((item) => item.side == 1).length &&
        slots.filter((item) => item.side == -1).length)
    ) {
      this.hasBackHS = true
    } else {
      this.hasBackHS = false
    }
    this.draw()
  },
  beforeDestroy() {
    let body = document.body
    body.style.overflow = 'auto'
  },
}
</script>

<style lang="less" scoped>
.holes_slots_wapper {
  position: fixed;
  z-index: 2100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  .cursor {
    cursor: pointer;
    user-select: none;
  }
  .holes_slots_popUp {
    width: 800px;
    height: 476px;
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    background-color: #fff;
    .HS_popUp_head {
      padding: 16px 24px;
      color: #333;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      background: #fafafa;
    }
    .HS_wapper {
      padding: 0 17px;
      .changeBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 0;
        > span {
          display: inline-block;
          width: 60px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #666;
          border: 1px solid #ddd;
          cursor: pointer;
          user-select: none;
          &:nth-child(1) {
            border-radius: 4px 0px 0px 4px;
          }
          &:nth-child(2) {
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
      display: flex;
      justify-content: space-between;
      .drawPlank_box {
        width: 300px;
        .change_f_b {
          .active {
            border-color: #18a8c7;
            color: #18a8c7;
          }
        }
        .onlyOneFace {
          > span {
            border-radius: 4px;
          }
        }
      }
      .HS_infoList {
        width: 450px;
        .change_h_s {
          .active {
            border-color: #18a8c7;
            color: #18a8c7;
          }
        }
        .HS_table {
          height: 330px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
            border-radius: 4px;
          }
          /* 滚动条滑块 */
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
          }
          &::-webkit-scrollbar-thumb:window-inactive {
            background: rgba(255, 0, 0, 0.4);
          }
          .HS_table_head {
            position: sticky;
            top: 0;
            background-color: #f5f5f5;
            height: 40px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #cccccc;
            border-top: 1px solid #cccccc;
          }
          .HS_table_body {
            .HS_table_item {
              cursor: pointer;
              user-select: none;
              height: 40px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #cccccc;
              background-color: #fff;
              &:hover {
                background-color: rgba(241, 248, 249);
              }
              &.active {
                background-color: rgba(241, 248, 249);
              }
            }
          }
          span {
            display: inline-block;
            padding-left: 16px;
          }
        }
      }
    }
  }
}
</style>
