import { getRawMaterialPlankInfo } from '@/apis/baseMaterial'
import { getProductionNcSetting } from '@/apis/equipment'
import { getAllKnifes } from '@/apis/paiban'
import { translateLang } from '@/data/i18n/translateLang'
import store from '@/store'
import { changeLayoutData, layoutStart } from '@/util/LayoutFuncs'
import { translate } from '@/util/commonFun'
import {
  dealLayoutResultData,
  genPaibanRequestParams,
  getPlateKnifeDiameter,
} from '@/util/dealPaibanData'
import {
  basisSettingExpandPlank,
  dealMorePlateKnife,
} from '@/util/plankCommonFuncs'
import { getMatCodeByHighPlankProp } from '@/util/plankCommonFuncs'
import { Modal } from 'ant-design-vue'
import Vue from 'vue'

/**
 *  计算匹配原片使用
 * @param parts 选中的小板
 * @param process_line_id 当前生产线
 * @param errCallback 错误的回调函数
 * @returns
 */
export async function calcBaseMaterial(
  parts: any[],
  selectStandardPlank: any,
  process_line_id: number,
  errCallback: (errType?: string, errText?: string) => any
) {
  const from = {}
  if (window.sessionStorage.getItem('thinkerx_material')) {
    Object.assign(from, { production_from: 'guimen' })
  }
  /** 获取原片数据的接口返回值 */
  const materialRes = await getRawMaterialPlankInfo({
    uid: store.state.userInfo.id,
    ...from,
  })
  // 没有原片数据直接返回
  if (materialRes.status !== 1) {
    // this.isShowLoading = false
    return false
  } else {
    function getDefalutFromList() {
      let plank
      const selectStandardPlankId = selectStandardPlank.standard_plank_id
      // 兼容默认的原片的id为0
      if (selectStandardPlankId || selectStandardPlankId === 0) {
        plank = materialRes.data.defaultPlankInfo.find(
          (p: any) => p.standard_plank_id == selectStandardPlankId
        )
      } else {
        plank = materialRes.data.defaultPlankInfo.find(
          (i: any) => i.default_selected
        )
      }
      return plank
    }
    const newStandardPlank = Array.isArray(materialRes.data.defaultPlankInfo)
      ? getDefalutFromList()
      : materialRes.data.defaultPlankInfo
    if (newStandardPlank) {
      Object.assign(selectStandardPlank, newStandardPlank)
      store.commit('setSelectStandardPlank', selectStandardPlank)
    }
  }
  // 是否是预排版方案，是就直接使用store存在的设置，不是获取新的nc设置
  const ncSettingRes =
    !process_line_id || process_line_id <= 0
      ? { data: store.state.ncSetting, status: 1 }
      : await getProductionNcSetting({
          setting_id: process_line_id,
          ...from,
        })
  // 获取失败直接返回
  if (ncSettingRes.status !== 1) {
    // this.isShowLoading = false
    if (ncSettingRes.data.err_info) {
      errCallback('setting', ncSettingRes.data.err_info)
    } else {
      errCallback('plank', translate('materialPage.noKnife'))
    }
    return
  }
  /** nc设置 */
  const ncSetting = ncSettingRes.data

  if (process_line_id && process_line_id > 0) {
    const allKnifes = await getAllKnifes({
      setting_id: process_line_id,
    })
    store.commit('setAllKnifes', allKnifes.data.data)
  }
  /** 判断是否没错误 */
  const errCB = (errType?: string, errText?: string) => {
    errCallback(errType, errText)
  }
  const noError =
    materialRes.data && judgePlateKnife(parts, ncSetting.plate_knife_map, errCB)
  // 有错直接返回
  if (!noError) {
    // this.isShowLoading = false
    return false
  }
  // 检测五六面钻模板是否太老 老版本直接返回
  if (oldFiveSixXYTip(ncSetting, errCB)) {
    // this.isShowLoading = false
    return false
  }
  /** 所选标准大板修边值 */
  const edge = selectStandardPlank.plankEdgeOff
  // 原片信息
  const { isAutoSelected, specialPlankInfo } = materialRes.data
  /** 原片数据数组 */
  const materialResData = JSON.parse(JSON.stringify(materialRes.data))
  /** 所选标准大板宽度 */
  const plankWidth = selectStandardPlank.plankWidth
  /** 所选大板长度 */
  const plankHeight = selectStandardPlank.plankHeight
  /** 大板允许的排版宽 */
  let useDefaultPlankWidth = plankWidth
  /** 大板允许的排版长 */
  let useDefaultPlankHeight = plankHeight
  // 重新聚类超大板
  const specialPlank: any = {}
  // 特殊大板信息
  specialPlankInfo.forEach((plank: any) => {
    // plank.uniqueId = nanoid()
    const { color, thick, matCode } = plank
    // 获取分材质下料刀的指定刀径大小
    const diameter = +getPlateKnifeDiameter(
      `${matCode}:${thick}:${color}`,
      ncSetting
    )
    // 切割宽度
    plank.useJudgeWidth = plank.width - plank.trim_edge * 2 + diameter
    // 切割长度
    plank.useJudgeHight = plank.height - plank.trim_edge * 2 + diameter
    const specialHighPlank = matCode.includes('高光_')
    /** 同typeSymbol */
    const symbol = `${matCode.replace(
      '高光_',
      ''
    )}:${thick}:${color}:${specialHighPlank}`
    plank.symbol = symbol
    if (Reflect.has(specialPlank, symbol)) {
      specialPlank[symbol].push(plank)
    } else {
      specialPlank[symbol] = [plank]
    }
  })

  // 根据宽高最长的重新排序
  Object.keys(specialPlank).forEach((k) => {
    specialPlank[k] = specialPlank[k].sort((a: any, b: any) => {
      const aMax = Math.max(a.width, a.height)
      const bMax = Math.max(b.width, b.height)
      return aMax - bMax
    })
  })
  // 已匹配上的原片
  const needMaterialList: any[] = []
  // 尺寸不能匹配的板件
  const notMatchPartList = []
  for (const part of parts) {
    const { texDir, texture, matCode, thick } = part

    /** 获取扩张距离 */
    const baRes = basisSettingExpandPlank(part, ncSetting)
    let { expandedX, expandedY, diameter } = baRes
    const { contourSizeRecoup, longPlankExpandedSize, finalExtendDisMap } =
      baRes
    diameter = +diameter
    // 计算公式：板件宽度 - 2倍修边 + 刀径 = 板件实际排版宽度
    useDefaultPlankWidth = plankWidth - edge * 2 + diameter
    useDefaultPlankHeight = plankHeight - edge * 2 + diameter
    // 无论异形还是矩形板都当作矩形来进行扩张判断是否超长
    // 判断是否是异形
    if (part.path) {
      expandedX = longPlankExpandedSize
      expandedY = longPlankExpandedSize
    }
    const rect = {
      width:
        part.rect.width + // 板子宽度
        expandedX * 2 + //长短边放量，异形全部是长边放量
        (finalExtendDisMap
          ? Number(finalExtendDisMap.left) + Number(finalExtendDisMap.right)
          : 0) + //拉直器
        contourSizeRecoup * 2 + // 玻璃切割机的处理
        diameter, //刀直径
      height:
        part.rect.height +
        expandedY * 2 +
        (finalExtendDisMap
          ? Number(finalExtendDisMap.top) + Number(finalExtendDisMap.bottom)
          : 0) +
        contourSizeRecoup * 2 +
        diameter,
    }
    part.blankWidth = Math.floor(rect.width * 100) / 100
    part.blankHeight = Math.floor(rect.height * 100) / 100
    part.originWidth = rect.width + edge * 2
    part.originHeight = rect.height + edge * 2

    // 根据板件材质，厚度，颜色，高光板来生成板件的唯一标识 lx
    const highPlank = part.is_high_gloss_plank
    const matCodeTemp = matCode.replace('高光_', '')
    const symbolArr = [
      part.symbol,
      `${matCodeTemp}:${thick}:${texture}:${highPlank}`,
      `${matCodeTemp}::${texture}:${highPlank}`,
      `${matCodeTemp}:${thick}::${highPlank}`,
      `:${thick}:${texture}:${highPlank}`,
      `${matCodeTemp}:::${highPlank}`,
      `::${texture}:${highPlank}`,
      `:${thick}::${highPlank}`,
      `:::${highPlank}`,
    ]
    // 检查当前板件是否已经有匹配的原片,已存在匹配则跳过
    // needMaterial是已经匹配上的原片
    const isExists = needMaterialList.find(
      (plank) =>
        part.symbol === plank.symbol &&
        rect.width <= plank.useJudgeWidth &&
        rect.height <= plank.useJudgeHight
    )
    if (isExists) continue
    // 是否需要再进行更大原片的匹配
    let isNeedMatchBiggerPlank = false
    // 是否有默认选用
    let flag = false
    let defaultPlank
    // 是否符号规则的特殊板材都未默认选用
    const notDefaultPlank = symbolArr
      .map((item) => specialPlank[item])
      .filter((item) => item)
      .flat(1)
      .every((plank) => !plank.isPicked)
    for (let i = 0; i < symbolArr.length; i++) {
      if (specialPlank[symbolArr[i]]) {
        defaultPlank = specialPlank[symbolArr[i]]?.find(
          (plank: any) => plank.isPicked
        )
        // 存在默认选用的板件
        if (defaultPlank) {
          // 长宽匹配则使用并且跳过，不匹配则看是否开启更大板件排版
          if (
            checkPlankIsAvailable(texDir, rect, {
              width: defaultPlank.useJudgeWidth,
              height: defaultPlank.useJudgeHight,
            })
          ) {
            const color = texture
            const newMatCode = getMatCodeByHighPlankProp(highPlank, matCode)
            needMaterialList.push({
              ...defaultPlank,
              matCode: newMatCode,
              // matCode: highPlank ? matCode : matCode.replace('高光_', ''),
              thick,
              color,
            })
            flag = true
            // 存在默认选用的大板，关闭超尺小板只排特殊大板
            materialRes.data.only_layout_too_big = false
            break
          }
        }
      }
    }
    //默认选用未匹配成功则看标准板材
    if (!flag) {
      // 符号规则的特殊板材都未默认选用且标准板材长宽匹配才使用标准板材
      if (
        notDefaultPlank &&
        checkPlankIsAvailable(texDir, rect, {
          width: useDefaultPlankWidth,
          height: useDefaultPlankHeight,
        })
      ) {
        continue
      }
      isNeedMatchBiggerPlank = true
      notMatchPartList.push(part)
    } else {
      continue
    }

    // 当前板件是否有需要使用超长板来排版
    if (!isNeedMatchBiggerPlank || !isAutoSelected || !notDefaultPlank) continue
    // 此处删除上面添加的当前板件
    notMatchPartList.length = notMatchPartList.length - 1
    // 记录是否成功匹配超大板
    let isMatch = false
    for (let i = 0; i < symbolArr.length; i++) {
      if (specialPlank[symbolArr[i]]) {
        for (const plank of specialPlank[symbolArr[i]]) {
          if (
            checkPlankIsAvailable(texDir, rect, {
              width: plank.useJudgeWidth,
              height: plank.useJudgeHight,
            })
          ) {
            const color = texture
            const newMatCode = getMatCodeByHighPlankProp(highPlank, matCode)
            needMaterialList.push({
              ...plank,
              thick,
              color,
              matCode: newMatCode,
              // matCode: highPlank ? matCode : matCode.replace('高光_', ''),
            })
            isMatch = true
            break
          }
        }
      }
      if (isMatch) {
        break
      }
    }

    if (!isMatch) {
      notMatchPartList.push(part)
    }
  }
  const setNotMatchPartList: any[] = []
  notMatchPartList.forEach((item) => {
    if (
      setNotMatchPartList.findIndex(
        (setItem) =>
          item.plankID === setItem.plankID &&
          item.partName === setItem.partName &&
          item.ggid === setItem.ggid
      ) === -1
    ) {
      setNotMatchPartList.push(item)
    }
  })
  return {
    setNotMatchPartList,
    autoAddSpecialPlate: materialRes.data.auto_add_special_plate,
    materialRes: materialResData,
    lackBaseMaterials: notMatchPartList,
    needBaseMaterials: [...new Set(needMaterialList)],
  }
}

/**
 * 是否刀具不符合规则
 * @param parts 小板数据
 * @param plate_knife_map 刀具库列表为一个对象
 * 数据格式{22:3:0:{
 *  diameter:6,
 *  error:"",
 *  id:108,
 *  knife:"default",
 *  matCode:"22",
 *  thick:23
 * }}
 * @param errCallback 错误回调
 * @returns boolean 是否符合规则
 */
export function judgePlateKnife(
  parts: any[],
  plate_knife_map: any,
  errCallback?: (errType?: string, errText?: string) => any
) {
  // 没有分材质下料刀直接返回true
  if (!plate_knife_map) return true
  /** 开料清单已选板件或者预选板件 */
  const selectPlanks = parts
  /** 处理板件列表 */
  const planksArr: any[] = []
  selectPlanks.forEach((item) => {
    // typeSymbol是由 matCode:thick:texture:isHighGlass 组成的
    const symbolArr = String(item.typeSymbol).split(':')
    planksArr.push(
      // 但是刀具匹配只需要matCode:thick
      symbolArr.slice(0, -2).join(':')
    )
  })
  /** 刀具列表的key值数组 由matCode:thick组成 样例：多层实木:18.0 */
  let knifesArr = Object.keys(plate_knife_map)
  knifesArr = knifesArr.map((item) => {
    // 如果小数部分为0，则取整数
    if (!Number(item.split('.')[1])) {
      item = item.split('.')[0]
    }
    return item
  })
  if (!intersection(knifesArr, planksArr)) return true
  let noError = true
  for (const key in plate_knife_map) {
    if (plate_knife_map[key].error) {
      noError = false
      errCallback && errCallback('plank', plate_knife_map[key].error)
      break
    }
  }
  return noError
}

export function dealDataForPaiban(
  plankList: any[],
  ncSetting: any,
  otherSetting: {
    isSpecialShape: boolean
    isNewLayout: boolean
    baseMaterialList: any[]
    surplusObj?: any
    isOnlyLayoutTooBig?: boolean
  }
) {
  // 多材质不同下料刀
  const moreMatCodePaibanData = dealMorePlateKnife(plankList, ncSetting)

  // prePaibanData进行push操作
  store.commit('setIsSinglePushPrePaibanData', true)
  // 清空perPaibanData
  store.commit('setPrePaibanData', [])
  const fnialList: any[] = []
  // 不同开料刀生成不同的请求数据
  moreMatCodePaibanData.forEach((paibanData) => {
    const {
      preLayoutData,
      setting: { diameter },
    } = paibanData
    const nc = JSON.parse(JSON.stringify(ncSetting))
    nc.knife.diameter = diameter
    changeLayoutData(preLayoutData)
    // 进行排版前的板件处理
    const val = layoutStart(nc)

    fnialList.push(val)
  })
  store.commit('setIsSinglePushPrePaibanData', false)
  if (store.state.errorPathPlankID.length) {
    Vue.prototype.$message({
      type: 'error',
      message: `板件内部存在异常数据（板号：${store.state.errorPathPlankID}），请检查！`,
    })
    return null
  }
  if (fnialList.some((it) => !it) || !fnialList.length) {
    Vue.prototype.$message({
      type: 'error',
      message:
        '<span id="material_knife_error">未设置下料刀直径, 请前往雕刻机设置刀具!</span>',
      dangerouslyUseHTMLString: true,
    })
    return null
  }
  if (fnialList.length) {
    const newList = fnialList.filter((i) => i)
    const slotErrList = newList.filter((i) => {
      return i && i.layout?.isErr
    })

    if (slotErrList.length) {
      Modal.error({
        title: translateLang('提示'),
        content: slotErrList[0] ? slotErrList[0].layout?.errMsg : '',
        centered: true,
      })
      return null
    }
  }
  const resultArr = []
  let hasErr = false
  for (const val of fnialList) {
    /** 处理上面layoutStart函数所返回的数据用于排版 */
    const data = dealLayoutResultData(
      val,
      otherSetting.baseMaterialList,
      {
        isSpecialShape: otherSetting.isSpecialShape,
        customPlankOrder: store.state.customPlankOrder,
        plankOrderList: store.state.plankOrderList,
      },
      ncSetting,
      store,
      otherSetting.isNewLayout,
      otherSetting.isOnlyLayoutTooBig
    )
    if (!data) {
      hasErr = true
      break
    }
    resultArr.push(data)
  }
  if (hasErr) {
    return null
  }
  let surplusObj = {}
  // 获取余料数据
  if (otherSetting.surplusObj) {
    // 处理余料数据 准备排版数据
    surplusObj = otherSetting.surplusObj
  }
  /** 参数列表 */
  const paramObjArr: any[] = []
  resultArr.forEach(({ result }) => {
    /** 生成排版接口所需参数 */
    const paramObj = genPaibanRequestParams(store, surplusObj, result)
    paramObjArr.push(paramObj)
  })
  return paramObjArr
}

/** 判断两个数组的包含关系 */
export function intersection(arr1: any[], arr2: any[]) {
  return Boolean(arr1.filter((i) => arr2.includes(i)).length)
}

/**
 * 旧版五六面钻设置提示
 * @param res 获取的ncSetting接口返回值
 * @param errCallback 错误的回调
 * @returns 返回boolean值
 */
export function oldFiveSixXYTip(res: any, errCallback: () => any) {
  // 五六面钻设置过老提示 (为啥是499，不明)
  if (res.status == 499) {
    const h = Vue.prototype.$createElement
    Vue.prototype
      .$msgbox({
        title: '警告',
        message: h('p', null, [
          h('i', { style: 'color: red' }, '五六面钻设置过老'),
          h('span', null, '，请联系售后人员重新对接'),
        ]),
        confirmButtonText: '确定',
      })
      .catch((e: any) => {
        throw e
      })
    errCallback && errCallback()
    return true
  } else {
    return false
  }
}

/**
 * 检查大板是否可用
 * @param texDir 小板纹理
 * @param partInfo 小板数据
 * @param plankInfo 大板数据
 * @returns 返回boolean值
 */
export function checkPlankIsAvailable(
  texDir: string,
  partInfo: any,
  plankInfo: any
) {
  // 横竖纹的判断 小板宽小于等于大板宽、小板长小于等于大板长
  if (texDir !== 'notcare') {
    return (
      partInfo.width <= plankInfo.width && partInfo.height <= plankInfo.height
    )
  } else {
    // 无纹理的判断 即小板矩形短边小于等于大板短边、小板长边小于等于大板长边同时满足
    const [partMin, partMax] = [partInfo.width, partInfo.height].sort(
      (a, b) => a - b
    )
    const [plankMin, plankMax] = [plankInfo.width, plankInfo.height].sort(
      (a, b) => a - b
    )
    return partMin <= plankMin && partMax <= plankMax
  }
}

export async function dealStandardPlank(
  selectStandardPlank: any,
  params: { uid: number; from?: 'guimen' }
) {
  if (selectStandardPlank) {
    return { ...selectStandardPlank, plankEdgeOff: selectStandardPlank.margin }
  } else {
    const res = await getRawMaterialPlankInfo(params)
    let defaultStandardPlank = null
    if (res.status) {
      defaultStandardPlank = Array.isArray(res.data.defaultPlankInfo)
        ? res.data.defaultPlankInfo.find((plank: any) => plank.default_selected)
        : res.data.defaultPlankInfo
    }
    return defaultStandardPlank
  }
}
