<template>
  <div
    class="container"
    v-if="loading"
    :style="type === 1 ? style.container : style.container2"
  >
    <div class="content" :class="{ content2: type !== 1 }">
      <!-- 在这轮播图 -->
      <div class="loading-box pl17 pt17 pr17 pb17 align-left">
        <p class="bold">{{ loadingTitle }}</p>
        <div class="load-item">
          <div class="felx flex-crosss--center">
            <img
              style="vertical-align: text-top"
              class="mr5"
              :src="img.tip"
              alt="图片加载失败"
            />
            <span style="color: #18a8c7"
              >{{
                `${
                  curBanner?.type == 1
                    ? $t('lunbo.tips')
                    : $t('lunbo.introduction')
                }`
              }}
            </span>
          </div>
          <p class="mt16 fs17 bold">{{ curBanner?.title }}</p>
          <p class="banner-container" v-html="curBanner?.content"></p>
        </div>
        <div class="load-item mt20 fs16 bold">
          <div>
            <span v-if="type !== '1'">{{ text }}</span>
            <slot name="text" v-if="type == 1" class="inline-block"></slot>
            <span class="progress-num absolute ml10 fs16" v-if="finish_percent"
              >{{ finish_percent }}%</span
            >
          </div>
          <el-steps
            :active="finishStep"
            align-center
            class="mt20"
            v-if="type === '2'"
          >
            <el-step v-for="(item, idx) in taskList" :key="item.type">
              <template #description>
                <g-t-view :text="item.type" width="100px" />
              </template>
              <template #icon v-if="finishStep == idx">
                <a-icon
                  class="absolute"
                  type="loading"
                  style="font-size: 26px"
                />
              </template>
            </el-step>
          </el-steps>
          <div class="progress-container" v-else>
            <div class="progress"></div>
          </div>
          <div
            class="fs15 mt20 progress-prompt align-center"
            v-if="type == '2' && isNowTimeOut && isWebToQt"
          >
            {{ $t('lunbo.qtDownloadText') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner } from '@/apis/common/banner'
import imgBg from '@/assets/image/loading_bg.png'
import imgTypefangkuai from '@/assets/image/loading_fangkuai.png'
import imgTypehuojian from '@/assets/image/loading_huojian.png'
import imgTypeqiu from '@/assets/image/loading_qiu.png'
import tipIcon from '@/assets/image/tips-icon.png'
import GTView from '@/components/translateComp/g-t-view.vue'

export default {
  components: { GTView },
  props: {
    useSlot: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
      required: true,
    },
    text: {
      type: String,
      default: '即将进入云排版……',
    },
    isWebToQt: {
      type: Boolean,
      default: false,
    },
    isTimeOut: {
      type: Boolean,
      default: false,
    },
    // 加载界面类型
    type: {
      type: [Number, String],
      default: 0,
    },
    // 图片类型  huojian  fangkuai qiu
    imgType: {
      type: String,
      default: 'huojian',
    },
    speed: {
      type: Object,
    },
    finish_percent: {
      type: Number,
    },
    taskList: {
      type: Array,
      default: () => [],
    },
    loadingTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      progressWidth: 0,
      timer: null,
      style: {
        container: {
          background:
            'linear-gradient(180deg,rgba(0, 0, 5, 0.8) 0%,rgba(0, 0, 0, 0.4) 100%)',
        },
        container2: {
          background: 'rgba(0,0,0,0.4)',
        },
        bannerList: [],
      },
      img: {
        huojian: imgTypehuojian,
        qiu: imgTypeqiu,
        fangkuai: imgTypefangkuai,
        bg: imgBg,
        tip: tipIcon,
      },
      isNowTimeOut: false,
      bannerTimer: null,
      curBanner: { title: '', type: 1, content: '' },
      bannerIndex: 0,
      finishStep: 1,
    }
  },
  watch: {
    loading: {
      handler() {
        if (this.loading) {
          this.progressWidth = 0
          getBanner({ effective_window: this.type }).then((res) => {
            if (res?.status == 1) {
              this.bannerList = res.data
              this.curBanner = this.bannerList[0]
              this.animate()
              this.bannerCycle()
            }
          })
        } else {
          this.bannerIndex = 0
          clearInterval(this.timer)
          clearInterval(this.bannerTimer)
        }
      },
      immediate: true,
    },
    bannerIndex: {
      handler(index) {
        this.curBanner = this.bannerList[index]
      },
    },
    isTimeOut: {
      handler(newName) {
        this.isNowTimeOut = newName
      },
      immediate: true,
      deep: true,
    },
    taskList: {
      handler(val) {
        val.forEach((item, index) => {
          if (item.startDone == true && item.endDone == false) {
            this.finishStep = index
          }
        })
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    animate(num) {
      let speed = num || 1000
      this.timer = setTimeout(() => {
        let step = (99 - this.progressWidth) / 99
        step = step > 0 ? Math.ceil(step) : Math.floor(step)
        this.progressWidth = this.progressWidth + step
        /** 进度每过10% 时间就增加为当前时间的2倍 */
        if (this.progressWidth % 10 === 0) {
          speed *= 2
        }
        if (this.progressWidth <= 99) {
          this.animate(speed)
        }
      }, speed)
    },
    bannerCycle() {
      this.bannerIndex = 0
      let maxIndex = this.bannerList.length - 1
      this.bannerTimer = setInterval(() => {
        if (this.bannerIndex < maxIndex) {
          this.bannerIndex = ++this.bannerIndex
        } else {
          this.bannerIndex = 0
        }
      }, 15000)
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.bannerTimer)
  },
}
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000000000;
  width: 100vw;
  min-width: 900px;
  height: 100vh;
  .content {
    position: absolute;
    top: 50%;
    left: 40%;
    width: 387px;
    transform: translate(-50%, -50%);
    .loading-img {
      width: 100%;
      height: 441px;
    }
    .loading-box {
      width: 920px;
      background: url('../../../../assets/image/loading_bg.png') no-repeat;
      background-size: cover;
    }
    .load-item {
      width: 100%;
      padding: 17px 24px 17px 24px;
      background-color: #fff;
    }
    .banner-container {
      color: rgba(0, 0, 0, 0.6);
    }
    .progress-container {
      width: 100%;
      height: 11px;
      margin: 20px 0 19px 56px;
      background: #f6f5f5;
      border-radius: 6px;
    }
    .text-left {
      margin-left: 126px;
    }
  }
  .content2 {
    position: absolute;
    top: 50%;
    left: 40%;
    width: 505px;
    text-align: center;
    transform: translate(-50%, -50%);
    .loading-img {
      width: 100%;
      height: 554px;
      opacity: 1;
    }
    .progress-container {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 11px;
      margin: 20px 0 15px 0;
      overflow: hidden;
      background: #f6f5f5;
      border-radius: 6px;
    }
  }
}

@keyframes loading {
  0% {
    left: -220px;
  }
  100% {
    left: 100%;
  }
}
.progress {
  position: absolute;
  width: 220px;
  height: 100%;
  background: linear-gradient(90deg, #f0faff 0%, #50d4ef 100%);
  border-radius: 6px;
  animation-name: loading;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
.progress-num {
  color: #79e8ff;
}
.progress-text {
  color: #fff;
  font-size: 18px;
}
.progress-prompt {
  color: rgb(239, 241, 128);
}
/deep/.el-step__main {
  width: 100px;
  margin-top: 10px;
}
/deep/.el-step__icon.is-text {
  border: 5px solid;
}
/deep/.el-step__icon {
  width: 1px;
  height: 1px;
}
/deep/.el-steps {
  margin-left: -40px;
}
</style>
