import { uploadTrialRecord } from '@/apis/paiban'
import { PartType } from '@/partTypes'
import { uploadJsonToOss } from '@yige/utils'
import { Message } from 'element-ui'

/**
 *发送试生产记录
 * @param nc nc接口返回数据
 * @param ncSetting nc设置
 * @returns
 */
export async function sendTrialRecord(nc: any[], ncSetting: any) {
  if (!nc.length) return
  for (const ncFile of nc) {
    if (
      (ncFile.error_info && !ncFile.plank_size_abnormal) ||
      (ncFile.filetype === 'nc' && ncFile.plank_size_abnormal)
    ) {
      return
    }
  }
  const fileUrl = await uploadJsonToOss(
    nc,
    'trial_record',
    'trial_production_record_dir',
    true
  )
  const result = await uploadTrialRecord({
    process_id: ncSetting.process_setting_id,
    process_name: ncSetting.process_setting_name,
    from: 'web',
    produce_file_url: fileUrl,
  })
  if (result.status !== 1) {
    Message.error({
      center: true,
      message: result.msg,
    })
  }
}
