import type { sendCaptchaType } from '@/apis/productionLineLock/types'
import { httpRequest } from '@/apis/request'
import HttpRequest from '@yige/request'

enum Apis {
  lockStatus = 'process_line_lock',
  sendCaptCha = 'send_code',
  verifiySave = 'verification_operator',
}
export const lockRequest = new HttpRequest({
  baseUrl: process.env.VUE_APP_BASE_URL,
  timeout: 1000000,
  tokenKey: 'token',
  cacheTokenMethods: 'sessionStorage',
  headers: {
    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
})

// 获取当前锁状态
export function getLockStatus(params: any) {
  return httpRequest.get(Apis.lockStatus, params)
}
// 发送验证码
export function getSendCaptcha(params: sendCaptchaType) {
  return httpRequest.post(Apis.sendCaptCha, params)
}
// 锁定生产线
export function lockProcessLine(params: any) {
  return lockRequest.post(Apis.lockStatus, params)
}
// 解锁生产线
export function unlockProcessLine(params: any) {
  return lockRequest.post(Apis.lockStatus, params)
}
// 重新设置锁密码
export function resetProcessLinePass(params: any) {
  return lockRequest.post(Apis.lockStatus, params)
}
//
export function getVerifiyData(params: any) {
  return lockRequest.post(Apis.verifiySave, params)
}
