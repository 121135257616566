import { getCurrentInstance, ref } from 'vue'

import type { IAddPartFormType } from './type'

type CallbackFn = (...args: any[]) => any

interface IUseAddPart {
  openModalCallback?: CallbackFn
  addPartSuccessCallback?: CallbackFn
}

// 用于开料清单添加板件
export const useAddPart = (callbackFns: IUseAddPart) => {
  const { openModalCallback, addPartSuccessCallback } = callbackFns
  const vm = getCurrentInstance()
  const defaultAddFormData = {
    plankID: 1,
    partName: '', // 板件名称
    matCode: '', // 材质
    is_high_gloss_plank: false, // 高光板
    texture: '', // 颜色
    thick: '', // 厚度
    specHeight: '', //成品长度
    specWidth: '', // 成品宽度
    amount: 1, // 数量
    texDir: 'normal', // 纹理
    frontEdge: '', // 前封边
    backEdge: '', // 后封边
    leftEdge: '', // 左封边
    rightEdge: '', // 右封边
    plank_remarks: '', // 板件备注
  } as const

  // 添加板件的表单数据
  const newPlankInfo = ref<IAddPartFormType>({ ...defaultAddFormData })

  // 是否展示添加板件的抽屉
  const isShowAddPartDrawer = ref(false)
  // 打开添加板件的抽屉
  const handleOpenAddDrawer = () => {
    openModalCallback && openModalCallback()
    isShowAddPartDrawer.value = true
  }
  // 关闭抽屉
  const handleCloseDrawer = () => {
    isShowAddPartDrawer.value = false
  }

  // 清空添加板件中的表单数据
  const clearNewPlankInfo = () => {
    newPlankInfo.value = { ...defaultAddFormData }
  }

  // 校验输入是否合法
  const validateInputValue = () => {
    // 必须的表单项
    const requiredFields = [
      'plankID',
      'matCode',
      'texture',
      'thick',
      'specHeight',
      'specWidth',
      'amount',
    ] as const
    // 表单label名称映射
    const propMapToLabel = {
      plankID: '板件ID',
      partName: '板件名称',
      matCode: '材质',
      texture: '颜色',
      thick: '厚度',
      specHeight: '成品长度',
      specWidth: '成品宽度',
      amount: '数量',
    }
    requiredFields.forEach((field) => {
      if (!newPlankInfo.value[field] && newPlankInfo.value[field] !== 0) {
        ;(vm?.proxy as any).$message({
          message: `<span id="material_check_error">${propMapToLabel[field]}不能为空!</span>`,
          type: 'error',
          duration: 1500,
          offset: 60,
          dangerouslyUseHTMLString: true,
        })
        throw new Error(`${propMapToLabel[field]}不能为空!`)
      }
    })
  }

  const handleConfirmAddPart = (isContinue = false) => {
    try {
      isAddLoading.value = true
      // 校验输入是否合法
      validateInputValue()
      // 执行校验成功的回调
      addPartSuccessCallback && addPartSuccessCallback()
      // 确定并继续
      if (isContinue) {
        // newPlankInfo初始化
        clearNewPlankInfo()
      } else {
        // 关闭抽屉
        isShowAddPartDrawer.value = false
      }
    } catch (error) {
      console.log('添加板件出错', error)
    } finally {
      setTimeout(() => {
        isAddLoading.value = false
      }, 500)
    }
  }

  // 记录正在添加的状态
  const isAddLoading = ref(false)
  return {
    newPlankInfo,
    isShowAddPartDrawer,
    isAddLoading,
    clearNewPlankInfo,
    handleOpenAddDrawer,
    handleCloseDrawer,
    handleConfirmAddPart,
  }
}
